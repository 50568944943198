import React, { useState } from "react";
import logo from "../assets/images/chc-logo.svg";
import { Link } from "react-router-dom";
import { useAuth } from "../context/auth";

function Header(props) {
  let { logout, user } = useAuth();
  const [isShown, setIsShown] = useState(false);
  const handleClick = event => {
    setIsShown(current => !current);
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top topnav" aria-label="primary navigation">
        <div className="d-flex">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="logo" className="logo" />
          </Link>
          <div className="d-flex align-items-center">
            <div className="chc-product">PO Generator</div>
          </div>
        </div>
        <div className="navbar-toggler-container">
          <button className="navbar-toggler border border-0" type="button" aria-label="Toggle navigation" onClick={handleClick}>
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className={"collapse navbar-collapse " + (isShown ? 'show' : '')}>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-user">
              {user && <span className="nav-link">
                {user.first_name} {user.last_name}
              </span>}
            </li>
            {/* <li className="nav-item">
              <NavLink to="/notifications" className="nav-link">
                <span className="material-symbols-sharp">notifications</span>
                <span className="d-md-block d-lg-none ms-1">Notifications</span>
              </NavLink>


            </li> */}
            <li className="nav-item">
              {user && <>
                <div className="nav-link clickable" onClick={() => { logout() }}>
                  <span className="material-symbols-sharp">logout</span>
                  <span className="d-md-block d-lg-none ms-1">logout</span>
                </div>

              </>
              }
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Header;
