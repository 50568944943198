import React, { useEffect, useState } from "react";
import * as service from "../../../services";
import { startLoading, stopLoading } from "../../../components/Util";
import Select from 'react-select';
import moment from "moment";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

const UsersGrid = (props) => {
  const navigate = useNavigate();
  const initialstate = { sort_by: { value: 'created_at', label: "Created On" }, record_count: 20, sort_direction: 'desc', is_deleted: false }
  const [loading, setLoading] = useState(true)
  const [store, setStore] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [payload, setPayload] = useState(JSON.parse(sessionStorage.getItem('user_filter')) || initialstate);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState();

  const sort_direction_options = [
    { value: 'name', label: "Name" },
    { value: 'email', label: "Email" },
    { value: 'created_at', label: "Created On" },
    { value: 'created_by_name', label: "Created By" },
    { value: 'status_name', label: "Status" }
  ];

  const handleChange = (prop, value) => {
    const _payload = JSON.parse(JSON.stringify(payload));
    _payload[prop] = value;
    setPayload(_payload);
  };

  const handleLoadMore = () => {
    setShowMore(false);
    let obj = store[store.length - 1];
    sessionStorage.setItem('user_filter', JSON.stringify(payload));
    const _payload = JSON.parse(JSON.stringify(payload));
    _payload.sort_by = _payload.sort_by.value;
    _payload.last_key = obj[_payload.sort_by + '_srt'];
    service.get_users(_payload, res => {
      setStore([...store, ...res.data]);
      if (res.data.length > 19) setShowMore(true)
    });
  };

  const sortClickHandler = val => {
    setPayload({ ...payload, sort_by: val, last_key: null });
  }

  const sort_directionClickHandler = () => {
    setPayload({ ...payload, sort_direction: payload.sort_direction === "asc" ? "desc" : "asc", last_key: null });
  }

  const handleReset = () => {
    setPayload(initialstate);
  }

  useEffect(() => {
    startLoading();
    sessionStorage.setItem('user_filter', JSON.stringify(payload));
    const _payload = JSON.parse(JSON.stringify(payload));
    if (_payload.created_from_date && _payload.created_to_date) {
      _payload.created_from_date = moment(payload.created_from_date).format("DD-MMM-yyyy") + ' 00:00:01';
      _payload.created_to_date = moment(payload.created_to_date).format("DD-MMM-yyyy") + ' 23:59:59';
    }
    _payload.sort_by = _payload.sort_by.value;
    service.get_users(_payload, res => {
      setStore(res.data);
      if (res.data.length > 19) setShowMore(true);
      setLoading(false)
    });
  }, [payload]);

  const removeUser = (data) => {
    const _payload = JSON.parse(JSON.stringify(payload));
    _payload.sort_by = _payload.sort_by.value;
    startLoading();
    service.upsert_user({ id: data.id, status_id: "DISABLED", operation_type: "DELETE", ...data }, res => {
      service.get_users(_payload, res => {
        if (res.data) {
          setStore(res.data);
          stopLoading();
          setDeleteModal(false);
        }
      });
    })
  }

  const handleShowDeleteUserModel = (data) => {
    setDeleteModal(true);
    setDeleteData(data);
  };

  const handleHideDeleteModal = () => {
    setDeleteModal(false);
  };

  const renderGrid = () => {
    return <>
      {store && store.length > 0 ?
        <div className="table-responsive">
          <table className="table table-flexible">
            <thead>
              <tr>
                <th scope="col" width="30%"><span className="text-muted">Name</span></th>
                <th scope="col"><span className="text-muted">Email</span></th>
                <th scope="col" width="15%"><span className="text-muted">Created By</span></th>
                <th scope="col" width="15%"><span className="text-muted">Updated By</span></th>
                <th scope="col" width="10%"><span className="text-muted">Status</span></th>
                <th scope="col" width="8%"><span className="text-muted">Action</span></th>
              </tr>
            </thead>
            <tbody>
              {store.map((data, key) => {
                const created_at = moment(new Date(data.created_at_ms));
                let created_at_dt = created_at.format('DD-MMM-YYYY hh:mm A');
                const updated_at = moment(new Date(data.updated_at_ms));
                let updated_at_dt = updated_at.format('DD-MMM-YYYY hh:mm A');
                return (
                  <tr key={data.id}>
                    <td className="text-nowrap">
                      <h6 className="clickable text-darkprime" onClick={() => { navigate("/admin/user-list/userdetail/" + data.id) }}><b>{data.first_name + ' ' + data.last_name}</b>
                      </h6>
                    </td>
                    <td>{data.email}</td>
                    <td>
                      {data.created_by_name}
                      <div style={{ fontSize: "0.75em", color: "#808080" }}>
                        <span>{created_at_dt} </span>
                      </div>
                    </td>
                    <td>
                      {data.updated_by_name}
                      {data.updated_by_name && <div style={{ fontSize: "0.75em", color: "#808080" }}>
                        <span>{updated_at_dt} </span>
                      </div>}
                    </td>
                    <td>{data.status_id}</td>
                    <td><span className="material-symbols-sharp clickable text-danger"
                      title={payload.is_deleted ? "Restore from Archive" : "Delete"}
                      onClick={() => { handleShowDeleteUserModel(data, key) }}>{payload.is_deleted ? <span onClick={() => { navigate("/admin/user-list/userdetail/" + data.id) }}>restore_from_trash</span> : 'Delete'}</span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        :
        <div className="blank-div">No Data Found.</div>
      }
      <div style={{ "textAlign": "center" }}> {store && store.length > 19 && showMore ? <button className="btn btn-outline-primary" onClick={() => handleLoadMore()} id="loadMore" > Show More ... </button> : undefined}</div>
    </>
  }

  loading ? startLoading() : stopLoading();
  return (
    !loading && <>
      <div className="container-fluid">
        <div className="row grid-head">
          <div className="col d-flex align-items-center">
            <h4>
              {payload.is_deleted === false ? "Users" : "Archived Users"}
            </h4>
          </div>

          <div className="col flex-grow-0">
            <button className="btn btn-outline-light btn-sm text-nowrap"
              onClick={() => { navigate("/admin/user-list/userdetail/0") }}>Create New User</button>
          </div>
        </div>
        <div className="row st-filter sticky-md-top">
          <div className="col-12">
            <div className="row pb-2 pt-1 justify-content-start">
              <div className="col-12 col-md-4 col-lg-6">
                <label htmlFor="Search" className="form-label mb-0"><small>SEARCH</small></label>
                <input placeholder='Search' maxLength="100" className="form-control"
                  value={payload.search_string || ''}
                  onChange={(e) => { handleChange('search_string', e.target.value) }} />
              </div>
              <div className="col flex-grow-1">
                <label className="form-label mb-0"><small>SORT BY</small></label>
                <Select
                  value={payload.sort_by || sort_direction_options[0]}
                  options={sort_direction_options || []}
                  className="st-select"
                  required
                  placeholder="Sort By"
                  onChange={e => {
                    sortClickHandler(e);
                  }}
                />
              </div>
              <div className="col flex-grow-0" style={{ minWidth: "115px" }}>
                <label className="form-label mb-0">&nbsp;</label>
                <div className="w-100" onClick={sort_directionClickHandler}>{payload.sort_direction === "desc" ?
                  <button type="button" className="btn btn-outline-secondary d-flex justify-content-center align-items-center w-100" style={{ maxHeight: "35px" }}><span className="material-symbols-sharp flip-180 me-1">sort</span>Z-A</button> :
                  <button type="button" className="btn btn-outline-secondary d-flex justify-content-center align-items-center w-100" style={{ maxHeight: "35px" }}><span className="material-symbols-sharp me-1">sort</span>A-Z</button>}
                </div>
              </div>
              <div className="col flex-grow-0" style={{ minWidth: "115px" }}>
                <label className="form-label mb-0">&nbsp;</label>
                <button type="button" className="btn btn-outline-secondary d-flex justify-content-center align-items-center w-100" onClick={() => handleReset()} style={{ maxHeight: "35px" }}><span className="material-symbols-sharp me-1">refresh</span>Reset</button>
              </div>
              <div className="col flex-grow-0 ps-0">
                <label className="form-label mb-0">&nbsp;</label>
                <span className="material-symbols-sharp clickable lh-base d-flex" onClick={(e) => {
                  handleChange('filter', !payload.filter)
                }} style={{ verticalAlign: "middle" }}>{payload.filter ? 'keyboard_double_arrow_up' : 'keyboard_double_arrow_down'}</span>
              </div>
            </div>
            {payload.filter &&
              <div className="row pb-2" style={{ marginTop: "-0.25rem" }}>
                <div className="col-4 col-md-4 col-lg-3">
                  <label className="form-label mb-0"><small>FROM DATE</small></label>
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text py-0 px-2">
                      <span className="material-symbols-sharp">calendar_month</span>
                    </span>
                    <div className="st-datepicker">
                      <DatePicker
                        placeholderText="From Date"
                        selected={payload.created_from_date ? new Date(payload.created_from_date) : null}
                        className="input form-control"
                        onChange={(date) => handleChange("created_from_date", date)}
                        dateFormat="dd-MMM-yyyy"
                        showMonthDropdown={true}
                        selectsStart
                        startDate={payload.created_from_date ? new Date(payload.created_from_date) : null}
                        endDate={payload.created_to_date ? new Date(payload.created_to_date) : null}
                        maxDate={payload.created_to_date ? new Date(payload.created_to_date) : null}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4 col-md-4 col-lg-3">
                  <label className="form-label mb-0"><small>TO DATE</small></label>
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text py-0 px-2">
                      <span className="material-symbols-sharp">calendar_month</span>
                    </span>
                    <div className="st-datepicker">
                      <DatePicker
                        placeholderText="To Date"
                        selected={payload.created_to_date ? new Date(payload.created_to_date) : null}
                        className="input form-control "
                        onChange={(date) => handleChange("created_to_date", date)}
                        dateFormat="dd-MMM-yyyy"
                        showMonthDropdown={true}
                        selectsEnd
                        startDate={payload.created_from_date ? new Date(payload.created_from_date) : null}
                        endDate={payload.created_to_date ? new Date(payload.created_to_date) : null}
                        minDate={payload.created_from_date ? new Date(payload.created_from_date) : null}
                      />
                    </div>
                  </div>
                </div>
                <div className="col flex-grow-0" style={{ minWidth: "266px" }} >
                  <label className="form-label mb-0"><small>STATUS</small></label>
                  <div className="btn-group d-flex" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" className="btn-check active" name="btnradio" id="Active" autoComplete="off" checked={!payload.is_deleted}
                      onChange={(e) => { handleChange('is_deleted', false) }} />
                    <label className="btn btn-outline-primary" style={{ flex: "1 50%" }} htmlFor="Active">Active</label>
                    <input type="radio" className="btn-check" name="btnradio" id="Archive" autoComplete="off" checked={payload.is_deleted}
                      onChange={(e) => { handleChange('is_deleted', true) }} />
                    <label className="btn btn-outline-primary" style={{ flex: "1 50%" }} htmlFor="Archive"  >Archived</label>
                  </div>
                </div>
              </div>}
          </div>
        </div>
        <div className="row px-0">
          {renderGrid()}
        </div>
        {deleteModal ? <div className="modal-backdrop fade show"></div> : ""}
        <div className={"modal fade " + (deleteModal ? "show d-block" : "d-none")}
          id="staticBackdrop"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-3">
              <div className="modal-body">
                <div className="row">
                  <div className="col">
                    <h4 className="text-center">Are you sure you want to delete the record?</h4>
                  </div>
                </div>
                <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-3">
                  <button className="btn btn-secondary" type="button"
                    onClick={handleHideDeleteModal}>
                    No
                  </button>
                  <button className="btn btn-primary text-white" type="button"
                    onClick={() => { removeUser(deleteData) }}>
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </>);
};
export default UsersGrid;
