import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../../services";
import { startLoading, stopLoading, validateEmail, randomString } from "../../../components/Util";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

function UserDetails(props) {


  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [recipe, setRecipe] = useState({ id, status_id: "NEW" });
  const [authRecipe, setAuthRecipe] = useState({});
  const [auths, setAuths] = useState([]);
  const [userId, setUserId] = useState(id);
  const [errors, setErrors] = useState({});
  const [passUpMessage, setpassUpMessage] = useState();
  const [passNWMessage, setpassNWMessage] = useState();
  const [passMessage, setPassMessage] = useState();
  const [lookups, setLookups] = useState({});
  const [validEmail, setValidEmail] = useState();
  const [disableSaveButton, setDisableSaveButton] = useState(false)
  


  useEffect(() => {
    startLoading();
    let p1 = new Promise((resolve, reject) => {
      if (id !== '0') {
        service.get_users({ id }, res => {
          if (res.data) {
            setRecipe({ ...res.data[0] });
            resolve("");
          }
        });
      } else resolve("");
    });

    let p2 = new Promise((resolve, reject) => {
      if (id !== '0') {
        service.get_user_roles({ user_id: userId }, res => {
          if (res.data) {
            setAuths(res.data);
            resolve("");
          }
        });
      } else resolve("");
    });

    let p3 = new Promise((resolve, reject) => {
      service.get_common_lookups({}, res => {
        setLookups(res);
        resolve("");
      });
    });

    Promise.all([p1, p2, p3])
      .then(values => {
        setLoading(false)
        stopLoading();
      })
      .catch(error => {
        console.log(error.message);
      });
    //eslint-disable-next-line 
  }, [userId]);

  useEffect(() => {
    if (!loading) {
      console.log("loaded...");
    }
  }, [loading]);

  const handleChange = (prop, value) => {
    setRecipe(recipe => ({ ...recipe, [prop]: value }));
  };

  const handleRoleChange = (prop, value) => {
    if (value.tier1 !== 'GLOBAL') {
      setAuthRecipe(authRecipe => ({ ...authRecipe, 'geo_id': undefined }));
    }
    setAuthRecipe(authRecipe => ({ ...authRecipe, [prop]: value }));
    if (value.tier1 === 'GLOBAL') {
      setAuthRecipe(authRecipe => ({ ...authRecipe, 'geo_id': { label: 'GLOBAL', value: 'GLOBAL' } }));
    }
  };

  const validate = () => {
    setErrors({});
    let isValid = true;
    let validation = [
      { field: 'first_name', msg: 'Please enter First Name.' },
      { field: 'last_name', msg: 'Please enter Last Name.' },
      { field: 'email', msg: 'Please enter Email.' }
    ];

    validation.map(item => {
      if (!recipe[item.field] || recipe[item.field].length <= 0) {
        setErrors(errors => ({ ...errors, [item.field]: item.msg }));
        isValid = false;
      }
      return item;
    });
    if (recipe.email && recipe.email.length > 0 && !validateEmail(recipe.email)) {
      setErrors(errors => ({ ...errors, 'email': 'Please enter valid email address.' }));
      isValid = false;
    }
    return isValid;
  };

  const upsert = () => {
    if (validate()) {
      startLoading();
      let payload = JSON.parse(JSON.stringify(recipe));
      if (payload.id === "0") delete payload.id;
      payload.operation_type = id === "0" ? "INSERT" : "UPDATE";
      payload.password = randomString(15);
      payload.is_deleted = "false";
      service.upsert_user(payload, res => {
        if (res.status === 'SUCCESS') {
          if (res.data.results) setUserId(res.data.results[0].id);
          if (userId === "0") setpassNWMessage("Record Created.");
          if (userId === "0") setDisableSaveButton(true);
          if (userId !== "0") setpassUpMessage("Record Updated.");
        }
        stopLoading();
        setTimeout(() => {
          setpassNWMessage()
          setpassUpMessage()
        }, 3000);
      });
    }
  }
  const checkEmail = () => {
    setValidEmail();
    let payload = JSON.parse(JSON.stringify(recipe));
    console.log("payload: ", payload);
    service.is_username_valid(payload, res => {
      if (res.data.is_email_valid !== true) setValidEmail("Record with same Email ID already exists.")
    })
  }

  const sendPassword = () => {
    if (validate()) {
      startLoading();
      let payload = JSON.parse(JSON.stringify(recipe));
      payload.operation_type = "RESET_PASSWORD";
      payload.user_id = userId;
      payload.id = userId;
      payload.password = randomString(15);
      service.send_password_by_id(payload, res => {
        stopLoading();
        if (res.status === 'SUCCESS') {
          setPassMessage("Password Sent.")
         let _id=id==='0'?userId:id;
          service.get_users({ id:_id }, res => {
            if (res.data) {
              setRecipe({ ...res.data[0] });
            }
          });
        }
        setTimeout(() => {
          setPassMessage()
        }, 3000);
      })
    }
  }

  const validateRoles = () => {
    setErrors({});
    let isValid = true;
    let validation = [
      { field: 'role_id', msg: 'Please select Role.' },
      { field: 'geo_id', msg: 'Please select Region.' },
    ];

    validation.map(item => {
      if (!authRecipe[item.field] || authRecipe[item.field].length <= 0) {
        setErrors(errors => ({ ...errors, [item.field]: item.msg }));
        isValid = false;
      }
      return item;
    });

    if (isValid) {
      let isExists = auths.filter(item => item.role_id.value === authRecipe.role_id.value && item.geo_id.value === authRecipe.geo_id.value).length > 0;
      if (isExists) {
        setErrors(errors => ({ ...errors, 'role_id': 'Combination already exists.' }));
        isValid = false;
      }
    }
    return isValid;
  }

  const addRole = () => {
    if (validateRoles()) {
      startLoading();
      let payload = JSON.parse(JSON.stringify(authRecipe));
      payload.geo_id = payload.geo_id.value;
      payload.role_id = payload.role_id.value;
      payload.user_id = userId;
      payload.operation_type = "INSERT";
      service.insert_user_role(payload, res => {
        service.get_user_roles({ user_id: userId }, res => {
          if (res.data) {
            setAuths(res.data);
            setAuthRecipe({})
            stopLoading();
          }
        });
      })
    }
  }

  const removeRole = (item) => {
    startLoading();
    service.delete_user_role({
      id: item.id,
      operation_type: "DELETE"
    }, res => {
      service.get_user_roles({ user_id: userId }, res => {
        if (res.data) {
          setAuths(res.data);
          stopLoading();
        }
      });
    })
  }

  let status_lookup = [];
  if (lookups.USER_STATUS) status_lookup = recipe.status_id + '' !== 'NEW' ? lookups.USER_STATUS.filter(item => item.value !== 'NEW') : lookups.USER_STATUS;

  if (lookups.ROLES && auths) {
    auths.map(item => {
      if (typeof item.role_id === 'string') item.role_id = lookups.ROLES.filter(r => r.value + '' === item.role_id + '')[0]
      if (typeof item.geo_id === 'string') item.geo_id = lookups.REGIONS.filter(r => r.value + '' === item.geo_id + '')[0]
      return item;
    })
  }
  return (
    <>
      <div className="container">
        <div className="row py-4 pb-1">
          <div className="col" style={{ display: "flex" }}>
            <h3 style={{ display: "inline", verticalAlign: "middle", paddingRight: "35%" }}>
              {userId !== '0' ? 'User Detail' : 'Create User'}
            </h3>
            {passNWMessage && <div className="me-5 text-darkprime">
              <b>{passNWMessage}</b>
            </div>}
          </div>
        </div>
        <div className="row py-3">
          <div className="col-6 mb-3">
            <label className="form-label">First Name</label>
            <input type="text" className="form-control" value={recipe.first_name || ''}
              onChange={(e) => handleChange('first_name', e.target.value)} />
            {errors.first_name && <div className="text-danger"><small>{errors.first_name}</small></div>}
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Last Name</label>
            <input type="text" className="form-control" value={recipe.last_name || ''}
              onChange={(e) => handleChange('last_name', e.target.value)} />
            {errors.last_name && <div className="text-danger"><small>{errors.last_name}</small></div>}
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Email</label>
            <input type="text" className="form-control" disabled={userId !== '0'} value={recipe.email || ''}
              onChange={(e) => handleChange('email', e.target.value.toLowerCase())} onBlur={() => { checkEmail() }} />
            {errors.email && <div className="text-danger"><small>{errors.email}</small></div>}
            {validEmail && <div className="text-danger"><small>{validEmail}</small></div>}
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Status</label>
            <Select value={(recipe.status_id && status_lookup && status_lookup.find(item => item.value === recipe.status_id + '')) || ''}
              options={status_lookup || []}
              isDisabled={userId === '0'}
              className="st-select"
              required
              placeholder="Status"
              onChange={(e) => { handleChange('status_id', e.value) }}
            />
            {errors.status_id && <div className="text-danger"><small>{errors.status_id}</small></div>}
          </div>
          <div className="col-12 d-flex justify-content-end mt-3 align-items-center">
            {passUpMessage && <div className="me-5 text-darkprime">
              <b>{passUpMessage}</b>
            </div>}
            {passMessage && <div className="me-5 text-darkprime">
              <b>{passMessage}</b>
            </div>}
            <button type="button" className="btn btn-secondary text-white btn-min me-2" onClick={e => { navigate(-1) }}>Back</button>
            <button type="button" disabled={userId === '0' || (auths && auths.length <= 0)} className="btn btn-min btn-success text-white text-nowrap me-2" onClick={e => { sendPassword() }}>
              Send Password
            </button>
            <button type="button" className="btn btn-min btn-success text-white text-nowrap" onClick={e => { upsert() }}
             disabled={validEmail||disableSaveButton}>
              {userId === '0' ? 'Save' : recipe.is_deleted === true ? "Restore" : "Update"}
            </button>
          </div>

          {userId !== '0' &&
            <>
              <div className="col flex-grow-1">
                <label className="form-label">Roles</label>
                <Select value={authRecipe.role_id || ''}
                  options={lookups.ROLES || []}
                  className="st-select"
                  required
                  placeholder="Role"
                  onChange={(e) => { handleRoleChange('role_id', e) }}
                />
                {errors.role_id && <div className="text-danger"><small>{errors.role_id}</small></div>}
              </div>
              <div className="col-6 mb-3">
                <label className="form-label">Region</label>
                <Select value={authRecipe.geo_id || ''} options={lookups.REGIONS || []} isDisabled={authRecipe.role_id && authRecipe.role_id.tier1 === 'GLOBAL'}
                  className="st-select" required placeholder="Region"
                  onChange={(e) => { handleRoleChange('geo_id', e) }}
                />
                {errors.geo_id && <div className="text-danger"><small>{errors.geo_id}</small></div>}
              </div>
              <div className="col flex-grow-0">
                <label className="form-label">&nbsp;</label>
                <button type="button" className="btn btn-outline-secondary d-flex justify-content-center align-items-center w-100" style={{ maxHeight: "35px" }}
                  onClick={e => { addRole() }}
                ><span className="material-symbols-sharp">add</span></button>
              </div>

              {auths && auths.length > 0 ?
                <div className="col-12 mt-3">
                  <table className="table">
                    <thead className="thead">
                      <tr>
                        <th><strong>Roles</strong></th>
                        <th><strong>Region</strong></th>
                        <th style={{ width: "10%" }}><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      {auths.map((item, index) => {
                        return <tr key={index}>
                          <td>
                            {item.role_id.label}
                          </td>
                          <td>
                            {item.geo_id.label}
                          </td>
                          <td>
                            <span className="material-symbols-sharp clickable" onClick={() => { removeRole(item, index) }}>delete</span>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </div>
                : <div className="col-12"><div className="blank-div">No Data Found.</div></div>}
            </>}
        </div>
      </div>
    </>
  )
}

export default UserDetails;
