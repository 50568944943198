import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { formatCurrency, getStatusClass, startLoading, stopLoading } from "../../components/Util";
import * as service from "../../services";

const ApprovalGrid = (props) => {
  const navigate = useNavigate();
  const initialstate = { sort_by: { value: 'created_at', label: "Created On" }, record_count: 20, sort_direction: 'desc', tabIndex: 0, status: 'ALL', assigned_to_me: true }
  const [loading, setLoading] = useState(true)
  const [store, setStore] = useState([]);
  const [lookups, setLookups] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [payload, setPayload] = useState(JSON.parse(sessionStorage.getItem('approval_filter')) || initialstate);
  const oprionAll = [{ label: 'All', value: '00000000-0000-0000-0000-000000000000' }];
  const oprionValAll = [{ label: 'All', value: 'All' }];

  const sort_direction_options = [
    { value: 'padded_po_number', label: "PO Number" },
    { value: 'contractor_name', label: "Contractor Name" },
    { value: 'care_home_name', label: "Care Home Name" },
    { value: 'created_by_name', label: "Created By" },
    { value: 'updated_by_name', label: "Updated By" },
    { value: 'work_type', label: "Work Type" },
    { value: 'service_group', label: "Service Group" },
    { value: 'created_at', label: "Created On" },
    { value: 'updated_at', label: "Updated On" },
    { value: 'net_price', label: "Net price" }
  ];

  const handleChange = (prop, value) => {
    const _payload = JSON.parse(JSON.stringify(payload));
    _payload[prop] = value;
    setPayload(_payload);
  };

  const handleLoadMore = () => {
    setShowMore(false);
    let obj = store[store.length - 1];
    sessionStorage.setItem('approval_filter', JSON.stringify(payload));
    const _payload = JSON.parse(JSON.stringify(payload));
    _payload.sort_by = _payload.sort_by.value;
    if (_payload.contractor_id) _payload.contractor_id = _payload.contractor_id.value;
    if (_payload.care_home_id) _payload.care_home_id = _payload.care_home_id.value;
    if (_payload.service_group) _payload.service_group = _payload.service_group.value;
    if (_payload.work_type) _payload.work_type = _payload.work_type.value;
    _payload.last_key = obj[_payload.sort_by + '_srt'];
    service.get_po_data("get-approval-data", _payload, res => {
      setStore([...store, ...res.data]);
      if (res.data.length > 19) setShowMore(true)
    });
  };

  const sortClickHandler = val => {
    setPayload({ ...payload, sort_by: val, last_key: null });
  }

  const sort_directionClickHandler = () => {
    setPayload({ ...payload, sort_direction: payload.sort_direction === "asc" ? "desc" : "asc", last_key: null });
  }

  const handleReset = () => {
    setPayload(initialstate);
  }

  useEffect(() => {
    startLoading();
    let p1 = new Promise((resolve, reject) => {
      sessionStorage.setItem('approval_filter', JSON.stringify(payload));
      const _payload = JSON.parse(JSON.stringify(payload));
      _payload.sort_by = _payload.sort_by.value;
      if (_payload.contractor_id) _payload.contractor_id = _payload.contractor_id.value;
      if (_payload.care_home_id) _payload.care_home_id = _payload.care_home_id.value;
      if (_payload.service_group) _payload.service_group = _payload.service_group.value;
      if (_payload.work_type) _payload.work_type = _payload.work_type.value;
      if (_payload.created_from_date && _payload.created_to_date) {
        _payload.created_from_date = moment(payload.created_from_date).format("DD-MMM-yyyy") + ' 00:00:01';
        _payload.created_to_date = moment(payload.created_to_date).format("DD-MMM-yyyy") + ' 23:59:59';
      }
      service.get_po_data("get-approval-data", _payload, res => {
        setStore(res.data);
        if (res.data.length > 19) setShowMore(true);
        resolve("");
      });
    });

    let p2 = new Promise((resolve, reject) => {
      service.get_common_lookups({}, res => {
        setLookups(res);
        resolve("");
      });
    });

    Promise.all([p1, p2])
      .then(values => {
        setLoading(false)
      })
      .catch(error => {
        console.log(error.message);
      });
  }, [payload]);

  const renderGrid = () => {
    return <>
      {store && store.length > 0 ? <div className="table-responsive">
        <table className="table table-flexible">
          <tbody>
            {store.map((data, key) => {
              let _className = getStatusClass(data.approval_status_name);
              const created_at = moment(new Date(data.created_at_ms));
              let created_at_dt = created_at.format('DD-MMM-YYYY hh:mm A');
              const updated_at = moment(new Date(data.updated_at_ms));
              let updated_at_dt = updated_at.format('DD-MMM-YYYY hh:mm A');
              return (<tr key={data.id}>
                <td className="po-order">
                  <small className="text-muted">PURCHASE ORDER</small> <br />
                  <h6 className="mb-1 clickable text-darkprime" onClick={() => { navigate("/approval-list/detail/" + data.id) }} ><b>{data.padded_po_number}</b></h6>
                  <span className={_className}>{data.approval_status_name}</span>
                </td>
                <td className="po-created">
                  <small className="text-muted">CREATED BY</small> <br />
                  {data.created_by_name}
                  <div style={{ fontSize: "0.75em", color: "#808080" }}>
                    <span>{created_at_dt} </span>
                  </div>
                </td>
                <td className="po-created">
                  <small className="text-muted">UPDATED BY</small> <br />
                  {data.updated_by_name}
                  <div style={{ fontSize: "0.75em", color: "#808080" }}>
                    <span>{updated_at_dt} </span>
                  </div>
                </td>
                <td className="po-created">
                  <small className="text-muted">ASSIGNED TO</small> <br />
                  {data.approval_status_id === 'PENDING' || 'ESCALATED' ? data.approval_assigned_to_name : undefined}
                  {/* <div style={{ fontSize: "0.75em", color: "#808080" }}>
                    <span>{created_at_dt} </span>
                    <span> {created_at_tm}</span>
                  </div> */}
                </td>
                <td className="po-contracter">
                  <small className="text-muted">CONTRACTOR</small> <br />
                  <div style={{ fontWeight: "600" }}>{data.contractor_name}</div>
                  <div style={{ fontSize: "0.75em", color: "#808080" }}>
                    {data.contractor_address_line_1}{data.contractor_address_line_2 ? (data.contractor_address_line_1 ? ', ' : undefined) + data.contractor_address_line_2 : undefined}
                    {data.contractor_address_line_1 || data.contractor_address_line_2 ? <br /> : ''}
                    {data.contractor_town ? data.contractor_town : undefined}
                    {data.contractor_county ? (data.contractor_town ? ', ' : undefined) + data.contractor_county : undefined}
                    {data.contractor_postcode ? (data.contractor_county ? ', ' : undefined) + data.contractor_postcode : undefined}
                  </div>
                </td>
                <td className="po-care">
                  <small className="text-muted">CARE HOME</small> <br />
                  <div style={{ fontWeight: "600" }}>{data.care_home_name}</div>
                  <div style={{ fontSize: "0.75em", color: "#808080" }}>
                    {data.care_home_address_line_1}{data.care_home_address_line_2 ? (data.care_home_address_line_1 ? ', ' : undefined) + data.care_home_address_line_2 : undefined}
                    {data.care_home_address_line_1 || data.care_home_address_line_2 ? <br /> : ''}
                    {data.care_home_town ? data.care_home_town : undefined}
                    {data.care_home_county ? (data.care_home_town ? ', ' : undefined) + data.care_home_county : undefined}
                    {data.care_home_postcode ? (data.care_home_county ? ', ' : undefined) + data.care_home_postcode : undefined}
                  </div>
                </td>
                <td className="po-service">
                  <small className="text-muted">SERVICE GROUP</small> <br />
                  {data.service_group} <br />
                  <small className="text-muted">WORK TYPE</small>
                  <br />
                  {data.work_type}
                </td>
                <td className="po-price">
                  <small className="text-muted">PRICE</small> <br />
                  £{formatCurrency(data.price, 2)}
                </td>
                <td className="po-vat">
                  <small className="text-muted">VAT</small> <br />
                  £{formatCurrency(data.vat, 2)}
                </td>
                <td className="po-net align-top">
                  <small className="text-muted">NET PRICE</small> <br />
                  £{formatCurrency(data.net_price, 2)}
                </td>
              </tr>)

            })}
          </tbody>
        </table>
      </div>
        : <div className="blank-div">No Data Found.</div>}
      <div style={{ "textAlign": "center" }}> {store && store.length > 19 && showMore ? <button className="btn btn-outline-success" onClick={() => handleLoadMore()} id="loadMore" > Show More ... </button> : undefined}</div>
    </>
  }

  // const downloadClickHandler = () => {
  //   alert("Coming Soon");
  // }

  loading ? startLoading() : stopLoading();
  return (
    !loading && <>
      <div className="container-fluid">
        <div className="row grid-head">
          <div className="col d-flex align-items-center">
            <h4>
              Approvals
            </h4>
          </div>

          {/* <div className="col flex-grow-0">
            <button className="btn btn-outline-light btn-sm text-nowrap" onClick={downloadClickHandler}>Download</button>
          </div> */}
        </div>

        <div className="row st-filter sticky-md-top">
          <div className="col-12">
            <div className="row pb-2 pt-1 justify-content-start">
              <div className="col-12 col-md-4 col-lg-6">
                <label htmlFor="Search" className="form-label mb-0"><small>SEARCH</small></label>
                <input placeholder='Search' maxLength="100" className="form-control"
                  value={payload.search_string || ''}
                  onChange={(e) => { handleChange('search_string', e.target.value) }} />
              </div>
              <div className="col flex-grow-1">
                <label className="form-label mb-0"><small>SORT BY</small></label>
                <Select
                  value={payload.sort_by || sort_direction_options[0]}
                  options={sort_direction_options || []}
                  className="st-select"
                  required
                  placeholder="Sort By"
                  onChange={e => {
                    sortClickHandler(e);
                  }}
                />
              </div>
              <div className="col flex-grow-1" style={{ minWidth: "115px" }}>
                <div className="col flex-grow-1" style={{ minWidth: "115px" }}>
                  <label className="form-label mb-0"><small>ASSIGNED TO</small></label>
                  <div className="btn-group d-flex" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" className="btn-check active" name="btnradio" id="me" autoComplete="off" checked={payload.assigned_to_me}
                      onChange={(e) => { handleChange('assigned_to_me', true) }} />
                    <label className="btn btn-outline-primary" style={{ flex: "1 50%" }} htmlFor="me">Me</label>
                    <input type="radio" className="btn-check" name="btnradio" id="everyone" autoComplete="off" checked={!payload.assigned_to_me}
                      onChange={(e) => { handleChange('assigned_to_me', false) }} />
                    <label className="btn btn-outline-primary" style={{ flex: "1 50%" }} htmlFor="everyone"  >Everyone</label>
                  </div>
                </div>
              </div >          <div className="col flex-grow-0" style={{ minWidth: "115px" }}>
                <label className="form-label mb-0">&nbsp;</label>
                <div className="w-100" onClick={sort_directionClickHandler}>{payload.sort_direction === "desc" ?
                  <button type="button" className="btn btn-outline-secondary d-flex justify-content-center align-items-center w-100" style={{ maxHeight: "35px" }}><span className="material-symbols-sharp flip-180 me-1">sort</span>Z-A</button> :
                  <button type="button" className="btn btn-outline-secondary d-flex justify-content-center align-items-center w-100" style={{ maxHeight: "35px" }}><span className="material-symbols-sharp me-1">sort</span>A-Z</button>}
                </div>
              </div>
              <div className="col flex-grow-0" style={{ minWidth: "115px" }}>
                <label className="form-label mb-0">&nbsp;</label>
                <button type="button" className="btn btn-outline-secondary d-flex justify-content-center align-items-center w-100" onClick={() => handleReset()} style={{ maxHeight: "35px" }}><span className="material-symbols-sharp me-1">refresh</span>Reset</button>
              </div>
              <div className="col flex-grow-0 ps-0">
                <label className="form-label mb-0">&nbsp;</label>
                <span className="material-symbols-sharp clickable lh-base d-flex" onClick={(e) => {
                  handleChange('filter', !payload.filter)
                }} style={{ verticalAlign: "middle" }}>{payload.filter ? 'keyboard_double_arrow_up' : 'keyboard_double_arrow_down'}</span>
              </div>
            </div>
            {
              payload.filter && <div className="row pb-2" style={{ marginTop: "-0.25rem" }}>
                <div className="col-12 col-md-4 col-lg-3">
                  <label className="form-label mb-0"><small>CONTRACTOR</small></label>
                  <Select value={payload.contractor_id || ''}
                    options={[...oprionAll, ...(lookups.CONTRACTOR)] || []}
                    className="w-100 st-select"
                    menuPlacement="auto"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 999999 }) }}
                    required
                    placeholder="Contractor"
                    onChange={(e) => { handleChange('contractor_id', e) }}
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <label className="form-label mb-0"><small>CARE HOME</small></label>
                  <Select value={payload.care_home_id || ''}
                    options={[...oprionAll, ...lookups.CARE_HOME] || []}
                    className="w-100 st-select"
                    menuPlacement="auto"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    required
                    placeholder="Care Home"
                    onChange={(e) => { handleChange('care_home_id', e) }}
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <label className="form-label mb-0"><small>SERVICE GROUP</small></label>
                  <Select value={payload.service_group || ''}
                    options={[...oprionValAll, ...(lookups.SERVICE_GROUP||[])] || []}
                    className="w-100 st-select"
                    menuPlacement="auto"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    required
                    placeholder="Service Group"
                    onChange={(e) => { handleChange('service_group', e) }}
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <label className="form-label mb-0"><small>WORK TYPE</small></label>
                  <Select value={payload.work_type || ''}
                    options={[...oprionValAll, ...(lookups.WORK_TYPE||[])] || []}
                    className="w-100 st-select"
                    menuPlacement="auto"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    required
                    placeholder="Work Type"
                    onChange={(e) => { handleChange('work_type', e) }}
                  />
                </div>
                <div className="col-4 col-md-4 col-lg-3">
                  <label className="form-label mb-0"><small>FROM DATE</small></label>
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text py-0 px-2">
                      <span className="material-symbols-sharp">calendar_month</span>
                    </span>
                    <div className="st-datepicker">
                      <DatePicker
                        placeholderText="From Date"
                        selected={payload.created_from_date ? new Date(payload.created_from_date) : null}
                        className="input form-control"
                        onChange={(date) => handleChange("created_from_date", date)}
                        dateFormat="dd-MMM-yyyy"
                        showMonthDropdown={true}
                        selectsStart
                        startDate={payload.created_from_date ? new Date(payload.created_from_date) : null}
                        endDate={payload.created_to_date ? new Date(payload.created_to_date) : null}
                        maxDate={payload.created_to_date ? new Date(payload.created_to_date) : null}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4 col-md-4 col-lg-3">
                  <label className="form-label mb-0"><small>TO DATE</small></label>
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text py-0 px-2">
                      <span className="material-symbols-sharp">calendar_month</span>
                    </span>
                    <div className="st-datepicker">
                      <DatePicker
                        placeholderText="To Date"
                        selected={payload.created_to_date ? new Date(payload.created_to_date) : null}
                        className="input form-control"
                        onChange={(date) => handleChange("created_to_date", date)}
                        dateFormat="dd-MMM-yyyy"
                        showMonthDropdown={true}
                        selectsEnd
                        startDate={payload.created_from_date ? new Date(payload.created_from_date) : null}
                        endDate={payload.created_to_date ? new Date(payload.created_to_date) : null}
                        minDate={payload.created_from_date ? new Date(payload.created_from_date) : null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="row px-0">
          <Tabs id="justify-tab-example" selectedIndex={payload.tabIndex || 0} onSelect={() => { }} className="mb-3">
            <TabList className="st-tablist">
              <Tab onClick={() => setPayload({ ...payload, status: 'ALL', tabIndex: 0 })}>
                <h5>All</h5>
                <div className="slide"></div>
              </Tab>
              <Tab onClick={() => setPayload({ ...payload, status: 'PENDING', tabIndex: 1 })}>
                <h5>Pending</h5>
                <div className="slide"></div>
              </Tab>
              <Tab onClick={() => setPayload({ ...payload, status: 'APPROVED', tabIndex: 2 })}>
                <h5>Approved</h5>
                <div className="slide"></div>
              </Tab>
              <Tab onClick={() => setPayload({ ...payload, status: 'REJECTED', tabIndex: 3 })}>
                <h5>Rejected</h5>
                <div className="slide"></div>
              </Tab>
              <Tab onClick={() => setPayload({ ...payload, status: 'ESCALATED', tabIndex: 4 })}>
                <h5>Escalated</h5>
                <div className="slide"></div>
              </Tab>
            </TabList>
            <TabPanel>{renderGrid()}</TabPanel>
            <TabPanel>{renderGrid()}</TabPanel>
            <TabPanel>{renderGrid()}</TabPanel>
            <TabPanel>{renderGrid()}</TabPanel>
            <TabPanel>{renderGrid()}</TabPanel>
          </Tabs>
        </div>
      </div >
    </>);
};
export default ApprovalGrid;
