import React from "react";
function Notification() {
  return (
    <>
      <h1>Comming soon...</h1>
    </>
  );
}

export default Notification;
