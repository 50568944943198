import React, { useState } from "react";
import { menuItems } from './menuItem';
import MenuItems from './MenuItems';
import { useAuth } from "../../context/auth";


function SubnavBar() {
    let { user } = useAuth();
    const [data] = useState(menuItems);

    let _data = data;
    if (user && user.user_types && !user.user_types.is_admin)
        _data = data.filter((item) => item.title !== "Administration");
    return (
        <nav className='navbar navbar-expand-lg fixed-top navbar-dark bg-primary subnav shadow-sm'>
            <div className='collapse navbar-collapse show'>
                <ul className="navbar-nav d-flex flex-row">
                    {_data.map((menu, index) => {
                        const depthLevel = 0;
                        return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
                    })}
                </ul>
            </div>
        </nav>
    );
};

export default SubnavBar;