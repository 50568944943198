import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../../services";
import { startLoading, stopLoading, validateEmail } from "../../../components/Util";
import { useNavigate } from "react-router-dom";

function ContractorRecord(props) {

  const { id } = useParams();
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState({ id, vat_apply: true });
  const [errors, setErrors] = useState({});
  const [duplicate, setDuplicate] = useState(undefined);
  const [shortNameDuplicate, setshortNameDuplicate] = useState(undefined);

  useEffect(() => {
    startLoading();
    if (id !== '0') {
      service.get_contractors({ id }, res => {
        if (res.data) {
          setRecipe({ ...res.data[0] });
        }
        stopLoading();
      });
    } else stopLoading();
  }, [id]);

  const handleChange = (prop, value) => {
    setRecipe(recipe => ({ ...recipe, [prop]: value }));
  };

  const validate = () => {
    setErrors({});
    let isValid = true;
    let validation = [
      { field: 'short_name', msg: 'Please enter Short Name.' },
      { field: 'contractor_name', msg: 'Please enter Contractor Name.' },
      { field: 'address_line_1', msg: 'Please enter Contractor Address1.' },
      { field: 'town', msg: 'Please enter City.' },
      { field: 'county', msg: 'Please enter County.' },
      { field: 'postcode', msg: 'Please enter Post Code.' },
      { field: 'phone_number', msg: 'Please enter Phone Number.' },
      { field: 'contact_email', msg: 'Please enter Contact Email.' },
      { field: 'notification_email', msg: 'Please enter Notification Email.' },
    ];

    if (recipe.vat_apply) {
      validation.push({ field: 'vat_number', msg: 'Please enter VAT Number.' })
    }

    if (recipe.contact_email && recipe.contact_email.length > 0 && !validateEmail(recipe.contact_email)) {
      setErrors(errors => ({ ...errors, 'contact_email': 'Please enter valid email address.' }));
      isValid = false;
    }
    if (recipe.notification_email && recipe.notification_email.length > 0 && !validateEmail(recipe.notification_email)) {
      setErrors(errors => ({ ...errors, 'notification_email': 'Please enter valid email address.' }));
      isValid = false;
    }
    if (recipe.phone_number && recipe.phone_number.length < 12) {
      setErrors(errors => ({ ...errors, 'phone_number': 'Phone Number should be 12 digits.' }));
      isValid = false;
    }

    validation.map(item => {
      if (!recipe[item.field] || recipe[item.field].length <= 0) {
        setErrors(errors => ({ ...errors, [item.field]: item.msg }));
        isValid = false;
      }
      return item;
    });
    return isValid;
  }

  const upsert = () => {
    if (validate()) {
      startLoading();
      let payload = JSON.parse(JSON.stringify(recipe));
      if (payload.id === "0") delete payload.id;
      if (payload.vat_apply === false) payload.vat_number = '';
      payload.operation_type = id === "0" ? "INSERT" : "UPDATE";
      payload.status_id = 'ACTIVE';
      payload.is_deleted = "false";
      service.upsert_contractor(payload, res => {
        navigate("/admin/contractor-list");
      });
    }
  }

  const isShortNameExist = () => {
    setshortNameDuplicate(undefined);
    let payload = JSON.parse(JSON.stringify(recipe));
    service.duplicate_check_sname(payload, res => {
      if (res.data.is_short_name_exists) setshortNameDuplicate("Record with same Short Name already exists.");
    })

  }

  const checkDuplicate = () => {
    setDuplicate(undefined);
    let payload = JSON.parse(JSON.stringify(recipe));
    service.duplicate_check_contractor(payload, res => {
      if (!res.data.is_contractor_name_valid) setDuplicate("Record with same Contractor name already exists.");
    })
  }

  return (
    <>
      <div className="container">
        <div className="row py-4 pb-1">
          <div className="col">
            <h3 style={{ display: "inline", verticalAlign: "middle", paddingRight: "20px" }}>
              {id !== '0' ? "Contractor Record" : "Create Contractor Record"}
            </h3>
          </div>
        </div>

        <form>
          <div className="row py-3">
            <div className="col-6 mb-3">
              <label className="form-label">Short Name</label>
              <input type="text" maxLength='20' disabled={id !== '0'} className="form-control" value={recipe.short_name || ''}
                onBlur={() => { isShortNameExist() }}
                onChange={(e) => handleChange('short_name', e.target.value)} />
              {errors.short_name && <div className="text-danger"><small>{errors.short_name}</small></div>}
              {shortNameDuplicate && <div className="text-danger"><small>{shortNameDuplicate}</small></div>}
            </div>

            <div className="col-6 mb-3">
              <label className="form-label">Contractor Name</label>
              <input type="text" className="form-control" value={recipe.contractor_name || ''}
                onBlur={() => { checkDuplicate() }}
                onChange={(e) => handleChange('contractor_name', e.target.value)} />
              {errors.contractor_name && <div className="text-danger"><small>{errors.contractor_name}</small></div>}
              {duplicate && <div className="text-danger"><small>{duplicate}</small></div>}
            </div>

            <div className="col-6 mb-3">
              <label className="form-label">Address 1</label>
              <input type="text" className="form-control" value={recipe.address_line_1 || ''}
                onChange={(e) => handleChange('address_line_1', e.target.value)} />
              {errors.address_line_1 && <div className="text-danger"><small>{errors.address_line_1}</small></div>}
            </div>


            <div className="col-6 mb-3">
              <label className="form-label">Address 2</label>
              <input type="text" className="form-control" value={recipe.address_line_2 || ''}
                onChange={(e) => handleChange('address_line_2', e.target.value)} />
            </div>

            <div className="col-6 mb-3">
              <label className="form-label">Town/City</label>
              <input type="text" className="form-control" value={recipe.town || ''}
                onChange={(e) => handleChange('town', e.target.value)} />
              {errors.town && <div className="text-danger"><small>{errors.town}</small></div>}
            </div>

            <div className="col-6 mb-3">
              <label className="form-label">County</label>
              <input type="text" className="form-control" value={recipe.county || ''}
                onChange={(e) => handleChange('county', e.target.value)} />
              {errors.county && <div className="text-danger"><small>{errors.county}</small></div>}
            </div>

            <div className="col-6 mb-3">
              <label className="form-label">Post Code</label>
              <input type="text" className="form-control" value={recipe.postcode || ''}
                onChange={(e) => handleChange('postcode', e.target.value)} />
              {errors.postcode && <div className="text-danger"><small>{errors.postcode}</small></div>}
            </div>

            <div className="col-6 mb-3">
              <label className="form-label">Contact Email</label>
              <input type="text" className="form-control" value={recipe.contact_email || ''}
                onChange={(e) => handleChange('contact_email', e.target.value)} />
              {errors.contact_email && <div className="text-danger"><small>{errors.contact_email}</small></div>}
            </div>

            <div className="col-6 mb-3">
              <label className="form-label">Notification Email</label>
              <input type="text" className="form-control" value={recipe.notification_email || ''}
                onChange={(e) => handleChange('notification_email', e.target.value)} />
              {errors.notification_email && <div className="text-danger"><small>{errors.notification_email}</small></div>}
            </div>

            <div className="col-6 mb-3">
              <label className="form-label">Phone Number</label>
              <input type="text" className="form-control" pattern="[0-9]*" maxLength="12" value={recipe.phone_number || ''}
                onBlur={(e) => handleChange('phone_number', ((e.target.validity.valid) ? e.target.value : ''))}
                onChange={(e) => handleChange('phone_number', ((e.target.validity.valid) ? e.target.value : ''))} />
              {errors.phone_number && <div className="text-danger"><small>{errors.phone_number}</small></div>}
            </div>
            <div className="col-6 mb-3 d-flex flex-nowrap text-nowrap">
              <input type="checkbox" className="form-check-input" id="vat_apply" checked={recipe.vat_apply || ''}
                onChange={(e) => handleChange('vat_apply', e.target.checked)} />
              <label className="form-check-label ms-3" htmlFor="vat_apply">Include VAT</label>
            </div>
            {recipe.vat_apply && <div className="col-6 mb-3">
              <label htmlFor="vat_number" className="form-label">VAT Number</label>
              <input type="text" className="form-control" value={recipe.vat_number || ''}
                onChange={(e) => handleChange('vat_number', e.target.value)} />
              {errors.vat_number && <div className="text-danger"><small>{errors.vat_number}</small></div>}
            </div>}
            <div className="col-12 d-flex justify-content-end mt-3">
              <button type="button" className="btn btn-secondary text-white btn-min me-2" onClick={e => { navigate(-1) }}>Cancel</button>
              <button type="button" className="btn btn-min btn-success text-white " onClick={upsert} disabled={duplicate || shortNameDuplicate}>
                {id === '0' ? 'Create' : recipe.is_deleted === true ? "Restore" : "Update"}
              </button>
            </div></div>
        </form>
      </div>
    </>
  )
}
export default ContractorRecord;
