import { post } from "./service";

export const get_contractors = (payload, onDone) => {
    post("/api/contractors/grid", payload, (res) => {
        onDone(res);
    });
};

export const upsert_contractor = (payload, onDone) => {
    post("/api/contractors/upsert", payload, (res) => {
        onDone(res);
    });
};

export const duplicate_check_contractor = (payload, onDone) => {
    post("/api/contractors/duplicate_check", payload, (res) => {
        onDone(res);
    });
};

export const duplicate_check_sname = (payload, onDone) => {
    
    post("/api/contractors/short_name_check", payload, (res) => {
        console.log("dfddd res",res);
        onDone(res);
    });
};
