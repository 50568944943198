export const menuItems = [
  {
    title: "Purchase Orders",
    icon: "attach_money",
    url: "dashboard",
  },
  {
    title: "Approvals",
    icon: "check_circle",
    url: "approval-list",
  },
  {
    title: "Administration",
    icon: "admin_panel_settings",
    url: "admin",
    submenu: [
      {
        title: 'Care Homes',
        icon: "House",
        url: 'admin/care-home-list',
      },
      {
        title: 'Contractors',
        icon: "View_List",
        url: 'admin/contractor-list',
      },
      {
        title: 'Users',
        icon: "group",
        url: 'admin/user-list',
      },
      {
        title: 'Approval Admin',
        icon: "verified",
        url: 'admin/approval-admin',
      }
    ]
  }
];