import React from "react";
function Action1() {
  return (
    <>
      <h1>Action1</h1>
    </>
  );
}

export default Action1;
