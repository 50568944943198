import Dropdown from './Dropdown';
import { useState, useRef, useEffect } from "react";
import { NavLink } from 'react-router-dom';

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  let ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    }; document.addEventListener('mouseover', handler)
  }, [dropdown]);

  return (
    <li className="nav-item"
      ref={ref}
      onClick={closeDropdown}
    >
      {items.submenu && items.url ? (
        <>
          <NavLink to={items.url} className={depthLevel === 0 ? 'nav-link dropdown-toggle ' : 'dropdown-item dropdown-toggle-right '}
            aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"}
            onClick={(evt) => { evt.preventDefault(); setDropdown((prev) => !prev) }}>
            <span className="material-symbols-sharp">{items.icon}</span>
            {items.title}
          </NavLink>
          <Dropdown submenus={items.submenu}
            dropdown={dropdown}
            depthLevel={depthLevel} />
        </>
      ) : !items.submenu && depthLevel === 0 ? (
        <NavLink to={items.url} className="nav-link "><span className="material-symbols-sharp">{items.icon}</span>{items.title}</NavLink>
      ) : (
        <NavLink to={items.url} className="dropdown-item "><span className="material-symbols-sharp">{items.icon}</span>{items.title}</NavLink>
      )}
    </li>
  );
};

export default MenuItems;