import { arrayToMap } from "../components/Util";
import { get, post } from "./service";

export const get_approval_configs = (payload, onDone) => {
    post("/api/approval_config/grid", payload, (res) => {
        onDone(res);
    });
};

export const get_approvers_lookup = (payload, onDone) => {
    post("/api/approval_config/approval_config_users_lookup", payload, (res) => {
        const lookup = arrayToMap(res.data || [], 'family');
        onDone(lookup);
    });
};

export const upsert_approval_config = (payload, onDone) => {
    post("/api/approval_config/upsert", payload, (res) => {
        onDone(res);
    });
};

export const get_all_approvers_lookup = (onDone) => {
    get("/api/approval_config/all_approvers_lookup", (res) => {
        onDone(res.data);
    });
};

