import POGrid from "./pages/PurchaseOrder/POGrid";
import PODetail from "./pages/PurchaseOrder/PODetail";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import CareHomeGrid from "./pages/Administration/CareHome/CareHomeGrid";
import CareHomeRecord from "./pages/Administration/CareHome/CareHomeRecord";
import ApprovalGrid from "./pages/Approvals/ApprovalGrid";
import Notification from "./pages/Notification";
import UserProfile from "./pages/UserProfile";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import ResetPassword from "./pages/ResetPassword";
import { AuthProvider } from "./context/auth";
import { ProtectedRoute } from "./pages/ProtectedRoute";
import ContractorsGrid from "./pages/Administration/Contractors/ContractorsGrid";
import ContractorRecord from "./pages/Administration/Contractors/ContractorRecord";
import UsersGrid from "./pages/Administration/Users/UsersGrid";
import UserDetails from "./pages/Administration/Users/UserDetails";
import "react-datepicker/dist/react-datepicker.css";
import ApprovalDetail from "./pages/Approvals/ApprovalDetail";
import Layout from "./layout/Layout";
import ApprovalAdmin from "./pages/Approvals/ApprovalAdmin";

function App() {
  return (
    <BrowserRouter>

      <AuthProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" exact element={<Navigate to="/dashboard" />} />
            <Route path="dashboard">
              <Route index element={<ProtectedRoute><POGrid /></ProtectedRoute>} />
              <Route path="po/:id" element={<ProtectedRoute><PODetail /></ProtectedRoute>} />
            </Route>
            <Route path="approval-list">
              <Route index element={<ProtectedRoute><ApprovalGrid /></ProtectedRoute>} />
              <Route path="detail/:id" element={<ProtectedRoute><ApprovalDetail /></ProtectedRoute>} />
            </Route>
            <Route path="admin">
              <Route path="care-home-list">
                <Route index element={<ProtectedRoute><CareHomeGrid /></ProtectedRoute>} />
                <Route path="carehomerecord/:id" element={<ProtectedRoute><CareHomeRecord /></ProtectedRoute>} />
              </Route>
              <Route path="user-list">
                <Route index element={<ProtectedRoute><UsersGrid /></ProtectedRoute>} />
                <Route path="userdetail/:id" element={<ProtectedRoute><UserDetails /></ProtectedRoute>} />
              </Route>
              <Route path="contractor-list">
                <Route index element={<ProtectedRoute><ContractorsGrid /></ProtectedRoute>} />
                <Route path="contractorrecord/:id" element={<ProtectedRoute><ContractorRecord /></ProtectedRoute>} />
              </Route>

              <Route path="approval-admin">
                <Route index element={<ProtectedRoute><ApprovalAdmin /></ProtectedRoute>} />
              </Route>
            </Route>
            <Route path="/notifications" element={<ProtectedRoute><Notification /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
