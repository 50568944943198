import { post } from "./service";
import axios from "axios";
import { saveAs } from "file-saver";
import { startLoading, stopLoading, arrayToMap } from '../components/Util';

export const get_po_data = (query, payload, onDone) => {
  post("/api/pogen/po/" + query, payload, (res) => {
    onDone(res);
  });
};

export const download_po = (id, po_number, onDone) => {
  startLoading();
  axios({
    url: encodeURI("/api/pogen/download-po/" + id),
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')),
      'Access-Control-Allow-Origin': '*'
    }
  }).then((response) => {
    saveAs(response.data, po_number + ".pdf");
    stopLoading();
  });
};

export const download_pos = (obj, onDone) => {
  startLoading();
  axios({
    url: encodeURI("/api/pogen/download-pos"),
    method: 'POST',
    responseType: 'blob',
    data: obj,
    headers: {
      Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')),
      'Access-Control-Allow-Origin': '*'
    }
  }).then((response) => {
    saveAs(response.data, "purchase_order_report.xlsx");
    stopLoading();
  });
};


export const get_query_users_lookup = (payload, onDone) => {
  post("/api/pogen/query_users_lookup", payload, (res) => {
    const lookup = arrayToMap(res.data || [], 'family')
    onDone(lookup);
  });
};