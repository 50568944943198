import bigDecimal from "js-big-decimal";

export const startLoading = () => {
  document.getElementById("loader").style.display = "block";
}

export const stopLoading = () => {
  document.getElementById("loader").style.display = "none";
};

export const validateEmail = (email) => {
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  return pattern.test(email)
}

export const isEmpty = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop))
      return false;
  }
  return true;
}

export const randomString = (length) => {
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
}

export const formatCurrency = (price, dec) => {
  if (!dec) dec = 0;
  const mult = Math.pow(10, dec);
  const num = Math.round(price * mult) / mult;

  //console.log(num, bigDecimal.getPrettyValue(bigDecimal.round(num, 0)))
  try {
    return bigDecimal.getPrettyValue(bigDecimal.round(num, dec));
  } catch (e) {
    console.warn(e);
  }
};
export const getDistinctValArray = (data, key) => {
  return [...new Map(data.map(item => [item[key], item])).values()];
}

export const getStatusClass = (status) => {
  let _className = undefined;
  if (status === "Approved") _className = "badge bg-success";
  if (status === "Request Created") _className = "badge bg-info";
  if (status === "Pending Approval") _className = "badge bg-warning";
  if (status === "Pending") _className = "badge bg-warning";
  if (status === "Rejected") _className = "badge bg-danger";
  if (status === "Cancelled") _className = "badge bg-secondary";
  if (status === "Exception Found") _className = "badge bg-secondary";
  if (status === "Escalated") _className = "badge bg-secondary";
  return _className;
}

export const arrayToMap = (data, key) => {
  let map = {};
  data.map(item => {
    let _key = item[key];
    if (_key in map)
      map[_key].push(item);
    else
      map[_key] = [item];
    return undefined;
  });
  return map;
}

export const establish_user_types = (auths) => {
  let user_types = {
    is_admin: auths.filter(item => item.user_role_id === 'ADMIN').length > 0,
    is_qc: auths.filter(item => item.user_role_id === 'QC').length > 0,
    is_sper_user: auths.filter(item => item.user_role_id === 'SUPER.USER').length > 0
  };
  return user_types;
}