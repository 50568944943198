import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import * as service from "../../services";
import { startLoading, stopLoading } from "../../components/Util";

function ApprovalAdmin() {
  const { id } = useParams();
  const [store, setStore] = useState({});
  const [keys, setKeys] = useState([]);
  const [ruleModal, setRuleModal] = useState(false);
  const [rangeRecipe, setRangeRecipe] = useState({ geo_id_arr: [] });
  const [approvalUserLookups, setApprovalUserLookups] = useState([]);
  const [errors, setErrors] = useState({ from_amount: "" });
  //const [lookups, setLookups] = useState({});
  const [allApprovers, setAllApprovers] = useState([]);
  const [regionGrid, setRegionGrid] = useState(false)

  useEffect(() => {
    startLoading();

    // let p1 = new Promise((resolve, reject) => {
    //   service.get_common_lookups({}, (res) => {
    //     setLookups(res);
    //     resolve("");
    //   });
    // });

    let p2 = new Promise((resolve, reject) => {
      service.get_all_approvers_lookup((res) => {
        setAllApprovers(res);
        resolve("");
      });
    });

    let p3 = new Promise((resolve, reject) => {
      service.get_approval_configs({ id }, (res) => {
        if (res.data) {
          setAppConfigData(res.data, () => {
            resolve("");
          })
        }
      });
    });

    let p4 = new Promise((resolve, reject) => {
      service.get_approvers_lookup({}, (approvers) => {
        setApprovalUserLookups(approvers);
        resolve("");
      });
    });

    Promise.all([p2, p3, p4])
      .then((values) => {
        stopLoading();
      })
      .catch((error) => {
        console.log(error.message);
      });
    //eslint-disable-next-line
  }, []);

  const setAppConfigData = (data, cb) => {
    let map = {};
    let keys = [];
    data.map((item) => {
      let _key = item.from_amount + "_" + item.to_amount;
      if (_key in map) map[_key].push(item);
      else {
        map[_key] = [item];
        keys.push(_key);
        //console.log("map",item);
      }
      return undefined;
    });
    setStore(map);
    setKeys(keys);
    cb();
  }

  const handleRuleModal = (items, action) => {
    let payload = {
      to_amount: action === "Edit" ? items[0].to_amount : "",
      from_amount: action === "Edit" ? items[0].from_amount + "" : "",
      geo_id_arr: action === "Edit" ? [...items] : [],
    };
    let { geo_id_arr } = payload
    if (geo_id_arr) {
      geo_id_arr = geo_id_arr.map(g => {
        let lookup = approvalUserLookups["APPROOVAL_USERS " + g.geo_id]
        let isFound = lookup.filter(l => l.value === g.approver).length > 0;
        if (!isFound) g.approver = undefined;
        return g
      })
      payload.geo_id_arr = geo_id_arr;
    }

    setRangeRecipe(payload);
    setRuleModal(true);
    setRegionGrid(true);
  };


  const handleHideRuleModal = () => {
    setRuleModal(false);
    setErrors({});
  };

  const handleChange = (prop, value) => {
    setRangeRecipe({ ...rangeRecipe, [prop]: value });
  };

  const handleApproverChange = (value, seq) => {
    let { geo_id_arr } = rangeRecipe;
    geo_id_arr.map((g) => {
      if (g.seq === seq) g.approver = value.value;
      return g;
    });
    setRangeRecipe({ ...rangeRecipe });
  };

  const validateRules = () => {
    setErrors({});
    let isValid = true;
    let validation = [
      { field: "from_amount", msg: "Please enter from Ammount." },
      { field: "to_amount", msg: "Please enter to Ammount." },
    ];

    validation.map((item) => {
      if (!rangeRecipe[item.field] || rangeRecipe[item.field].length <= 0) {
        setErrors((errors) => ({ ...errors, [item.field]: item.msg }));
        isValid = false;
      }
      return item;
    });

    if (isValid) {
      if (rangeRecipe.geo_id_arr) {
        rangeRecipe.geo_id_arr.map((g) => {
          g.err = undefined;
          if (!g.approver || g.approver.length <= 0) {
            g.err = "Please select Approver.";
            isValid = false;
          }
          return g;
        });
      }
    }
    return isValid;
  };

  const upsert = () => {
    if (validateRules()) {
      startLoading()
      setRuleModal(false);
      let { geo_id_arr } = rangeRecipe;
      service.upsert_approval_config(geo_id_arr, res => {
        service.get_approval_configs({ id }, (res) => {
          if (res.data) {
            setAppConfigData(res.data, () => {
              stopLoading();
            })
          }
        });
      })
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row grid-head">
          <div className="col d-flex align-items-center">
            <h4>Approval Admin</h4>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row py-4 mb-3">
          <div className="col-sm-12">
            <div className="table-responsive">
              {keys.map((key, index) => {
                let items = store[key];
                return (
                  <div key={index}>
                    <table className="table table-flexible">
                      <thead>
                        <tr className="">
                          <th className="px-0 align-middle w-50">
                            <div className="d-flex align-items-center">
                              <div>Range </div>&nbsp;
                              <div style={{ fontWeight: "normal" }}>
                                {" £" +
                                  items[0].from_amount +
                                  " - £" +
                                  items[0].to_amount}
                              </div>
                            </div>
                          </th>
                          <th className="px-0 w-50">
                            <div className="d-grid d-md-flex justify-content-md-end gap-2">
                              <button
                                className="btn btn-outline-success text-nowrap "
                                type="button"
                                onClick={() => handleRuleModal(items, "Edit")}
                              >
                                Edit
                              </button>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item, key) => {
                          let approver_name =
                            allApprovers &&
                            allApprovers.filter(
                              (a) => a.value === item.approver
                            );
                          approver_name =
                            approver_name && approver_name.length > 0
                              ? approver_name[0].label
                              : undefined;
                          return (
                            <tr key={key}>
                              <td className="border border-1 w-50">
                                <div className="py-2">
                                  <span className="text-primary">
                                    {item.geo_id}
                                  </span>
                                </div>
                              </td>
                              <td className="border border-1 w-50">
                                {approver_name}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {ruleModal ? <div className="modal-backdrop fade show"></div> : ""}
        <div
          className={"modal fade " + (ruleModal ? "show d-block" : "d-none")}
          id="staticBackdrop"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  <strong>Add/Edit Range & Rules</strong>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleHideRuleModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      From Value
                    </label>
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text bg-light"
                        id="basic-addon1"
                      >
                        <span className="material-symbols-sharp">
                          currency_pound
                        </span>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={
                          rangeRecipe.from_amount + "" || ""}
                        placeholder=""
                        onChange={(e) =>
                          handleChange("from_amount", e.target.value)
                        }
                      />
                      {errors.from_amount && (
                        <div className="text-danger">
                          <small>{errors.from_amount}</small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      To Value
                    </label>
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text bg-light"
                        id="basic-addon1"
                      >
                        <span className="material-symbols-sharp">
                          currency_pound
                        </span>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={rangeRecipe.to_amount || ""}
                        placeholder=""
                        onChange={(e) =>
                          handleChange("to_amount", e.target.value)
                        }
                      />
                      {errors.to_amount && (
                        <div className="text-danger">
                          <small>{errors.to_amount}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {regionGrid ?
                  <div className="col mb-3 mt-3">
                    <div className="">
                      <table className="table">
                        <thead>
                          <tr>
                            <th width="30%">Region</th>
                            <th width="55%">Approvers</th>
                          </tr>
                        </thead>

                        <tbody>
                          {rangeRecipe.geo_id_arr.map((item, key1) => {
                            return (
                              <tr key={key1}>
                                <td>
                                  <div className="py-2">
                                    <span className="text-primary">
                                      {item.geo_id}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <div className="py-2 flex-grow-1">
                                      <Select
                                        value={approvalUserLookups["APPROOVAL_USERS " + item.geo_id].filter((v) => item.approver === v.value) || ""}
                                        options={approvalUserLookups["APPROOVAL_USERS " + item.geo_id]}
                                        classNamePrefix="select"
                                        className="st-select"
                                        required
                                        placeholder="Approver"
                                        onChange={(e) => {
                                          handleApproverChange(e, item.seq);
                                        }}
                                      />
                                      {item.err && (
                                        <div className="text-danger">
                                          <small>{item.err}</small>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  : undefined
                }
                <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-3">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={handleHideRuleModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary text-white"
                    type="button"
                    onClick={upsert}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApprovalAdmin;
