import { Outlet } from 'react-router-dom';
import Header from './Header';
import SubnavBar from '../components/subnav/SubnavBar'
import Footer from './Footer';

const Layout = () => {
  return (
    <div className="main">
      <Header />
        <SubnavBar />
        <div className="content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;