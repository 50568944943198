import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../../services";
import { startLoading, stopLoading } from "../../../components/Util";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

function CareHomeRecord(props) {

  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [recipe, setRecipe] = useState((id));
  const [errors, setErrors] = useState({});
  const [lookups, setLookups] = useState({});
  const [duplicate, setDuplicate] = useState()

  useEffect(() => {
    startLoading();
    let p1 = new Promise((resolve, reject) => {
      if (id !== '0') {
        service.get_care_homes({ id }, res => {
          if (res.data) {
            setRecipe({ ...res.data[0] });
            resolve("Promise resolved successfully");
          } else {
            reject(Error("Promise rejected"));
          }
        });
      } else stopLoading();
    });
    let p2 = new Promise((resolve, reject) => {
      service.get_common_lookups({}, res => {
        setLookups(res);
        //console.log(res);
        resolve("");
      });
    });
    Promise.all([p1, p2])
      .then(values => {
        setLoading(false)
      })
      .catch(error => {
        console.log(error.message);
      });

    stopLoading();
  }, [id]);

  useEffect(() => {
    if (!loading) {
      console.log("loaded...");
    }
  }, [loading]);

  const handleChange = (prop, value) => {
    setRecipe(recipe => ({ ...recipe, [prop]: value }));
  };

  const validate = () => {
    setErrors({});
    let isValid = true;
    let validation = [
      { field: 'care_home_name', msg: 'Please enter Care Home Name.' },
      // { field: 'care_home_code', msg: 'Please enter Care Home Code.' },
      { field: 'address_line_1', msg: 'Please enter Care Home Address.' },
      { field: 'town', msg: 'Please enter City.' },
      { field: 'county', msg: 'Please enter County.' },
      { field: 'postcode', msg: 'Please enter Post Code.' },
      { field: 'geo_id', msg: 'Please select Region.' },
      { field: 'facility_type', msg: 'Please select Facility Type.' }
    ];

    validation.map(item => {
      if (!recipe[item.field] || recipe[item.field].length <= 0) {
        setErrors(errors => ({ ...errors, [item.field]: item.msg }));
        isValid = false;
      }
      return item;
    });
    return isValid;
  }

  const upsert = () => {
    if (validate()) {
      startLoading();
      let payload = JSON.parse(JSON.stringify(recipe));
      if (payload?.care_home_code?.length < 1) delete payload.care_home_code;
      if (id === '0') {
        delete payload.id;
        payload.operation_type = "INSERT";
      } else {
        payload.operation_type = "UPDATE";
      }
      payload.is_deleted = "false";
      service.upsert_care_home(payload, res => {
        navigate("/admin/care-home-list");
      });
    }
  }

  const checkDuplicate = () => {
    setDuplicate();
    let payload = JSON.parse(JSON.stringify(recipe));
    //console.log("Record with same Care Home name already exists.", payload);
    service.duplicate_check_care_home(payload, res => {
      if (res.data.is_care_home_name_valid !== true) setDuplicate("Record with same Care Home name already exists.")
    })
  }

  return (
    <>
      <div className="container">
        <div className="row py-4 pb-1">
          <div className="col">
            <h3 style={{ display: "inline", verticalAlign: "middle", paddingRight: "20px" }}>
              {id !== '0' ? "Care Home Record" : "Create Care Home Record"}
            </h3>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-6 mb-3">
            <label className="form-label">Care Home Name</label>
            <input type="text" className="form-control" value={recipe.care_home_name || ''}
              onBlur={() => { checkDuplicate() }}
              onChange={(e) => handleChange('care_home_name', e.target.value)} />
            {errors.care_home_name && <div className="text-danger"><small>{errors.care_home_name}</small></div>}
            {duplicate && <div className="text-danger"><small>{duplicate}</small></div>}
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Care Home Code</label>
            <input type="text" className="form-control" value={recipe.care_home_code || ''} pattern="[0-9]*" maxLength={5}
              onChange={(e) => handleChange('care_home_code', ((e.target.validity.valid) ? e.target.value : ''))} />
            {errors.care_home_code && <div className="text-danger"><small>{errors.care_home_code}</small></div>}
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Address 1</label>
            <input type="text" className="form-control" value={recipe.address_line_1 || ''}
              onChange={(e) => handleChange('address_line_1', e.target.value)} />
            {errors.address_line_1 && <div className="text-danger"><small>{errors.address_line_1}</small></div>}
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Address 2</label>
            <input type="text" className="form-control" value={recipe.address_line_2 || ''}
              onChange={(e) => handleChange('address_line_2', e.target.value)} />
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Town/City</label>
            <input type="text" className="form-control" value={recipe.town || ''}
              onChange={(e) => handleChange('town', e.target.value)} />
            {errors.town && <div className="text-danger"><small>{errors.town}</small></div>}
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">County</label>
            <input type="text" className="form-control" value={recipe.county || ''}
              onChange={(e) => handleChange('county', e.target.value)} />
            {errors.county && <div className="text-danger"><small>{errors.county}</small></div>}
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Post Code</label>
            <input type="text" className="form-control" value={recipe.postcode || ''}
              onChange={(e) => handleChange('postcode', e.target.value)} />
            {errors.postcode && <div className="text-danger"><small>{errors.postcode}</small></div>}
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Region</label>
            <Select value={(recipe.geo_id && lookups.REGIONS && lookups.REGIONS.find(item => item.value === recipe.geo_id + '')) || ''}
              options={(lookups.REGIONS && lookups.REGIONS.filter(item => item.value !== 'GLOBAL')) || []}
              className="form-select st-select"
              required
              placeholder="Region"
              onChange={(e) => { handleChange('geo_id', e.value) }}
            />
            {errors.geo_id && <div className="text-danger"><small>{errors.geo_id}</small></div>}
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Facility Type</label>
            <Select value={(recipe.facility_type && lookups.FACILITYTYPES && lookups.FACILITYTYPES.find(item => item.value === recipe.facility_type + '')) || ''}
              options={lookups.FACILITYTYPES || []}
              className="form-select st-select"
              required
              placefacility_typeer="facility_type Type"
              onChange={(e) => { handleChange('facility_type', e.value) }}
            />
            {errors.facility_type && <div className="text-danger"><small>{errors.facility_type}</small></div>}
          </div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <button type="button" className="btn btn-secondary text-white btn-min me-2" onClick={e => { navigate(-1) }}>Cancel</button>
            <button type="button" className="btn btn-min btn-success text-white text-nowrap" onClick={e => { upsert() }} disabled={duplicate}>
              {id === '0' ? 'Create' : recipe.is_deleted === true ? "Restore" : "Update"}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CareHomeRecord;
