import logo from "../assets/images/chc-logo.svg";
import React, { useState } from "react";
import * as service from "../services";
import { startLoading, stopLoading, validateEmail, randomString } from "../components/Util";
import { useNavigate } from "react-router-dom";

function Forgot(props) {
  const navigate = useNavigate();
  const [username, setUserName] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setMessage] = useState();

  const validate = () => {
    setErrors({});
    let isValid = true;
    if (!username || username.length <= 0) {
        setErrors({ loginFailure: 'Please enter email address.' });
        isValid = false;
    }

    if (username && username.length > 0 && !validateEmail(username)) {
      setErrors({ loginFailure: 'Please enter valid email address.' });
      isValid = false;
    }

   console.log(username);

    return isValid;
};
const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
}

  const handleSubmit = () => {
    if(validate()){
    startLoading();
    let temp_pass = randomString(15);
    service.forgot_password({ username, password: temp_pass }, (res) => {
      // console.log("Res Stuatus: ", res.status);
      if (res && res.status) {
        if (res.status === "SUCCESS") setMessage(res.msg); setErrors({ loginFailure: "" })
        if (res.status === "ERROR") setErrors({ loginFailure: res.msg })
        if (!username || username.length <= 0) setErrors({ loginFailure: 'Please enter Email address.' })
        stopLoading();
      }
    });
  }
  };

  return (
    <>
      <div className="login-section d-flex justify-content-center">
        <div className="w-100">
          <div className="col-md-6 col-lg-5 login-wrap p-5 mx-auto shadow">
            <img src={logo} alt="logo" style={{ width: "220px", margin: "2rem auto", display: "block" }}
            />

            <form className="login-form">
              {!successMessage &&
                <>
                  <div className="form-floating mb-3">
                    <input type="email" className="form-control" id="floatingInput"
                      placeholder="name@example.com" value={username || ""}
                      onChange={(e) => setUserName(e.target.value)}
                      required
                      autoFocus
                    />
                    <label htmlFor="floatingInput">Email address.</label>
                  </div>
                  <p className="text-danger"><small>{errors.loginFailure}</small></p>
                  <div className="col-12 d-flex justify-content-between py-4">
                    <button type="button" className="btn btn-secondary text-white btn-min btn-lg w-50 me-2" onClick={() => navigate('/login')}>Cancel</button>
                    <button type="button" className="btn btn-success text-white btn-min btn-lg w-50 ms-2"
                    onKeyDown={handleKeyDown}
                      onClick={() => { handleSubmit(); }}><small>Submit</small>
                    </button>
                  </div>
                </>
              }

              {successMessage &&
                <> <p className="pt-4">We have sent you an email with a temporary password.</p>
                  <div className="form-group d-md-flex py-4">
                    <button className="btn btn-success text-white btn-min btn-lg w-50 mx-auto"
                      style={{ color: "#ffffff" }} type="button"
                      onClick={() => navigate("/login")}>OK
                    </button>
                  </div>
                </>
              }
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forgot;
