import { useState } from "react";
import logo from "../assets/images/chc-logo.svg";
import * as service from "../services";
import { startLoading, stopLoading, isEmpty } from '../components/Util';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function ResetPassword(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [username, setUserName] = useState(location.state);
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [errors, setError] = useState({})

    const validate = () => {
        let errors = {}
        if (!username || username.length <= 0) errors.username = 'Please enter Email Address.';
        setError(errors)
        return isEmpty(errors)
    }

    const validatePassword = () => {
        let errors = {}
        var errmsg;
        if (!password1 || password1.length <= 0) {
            errors.password1 = 'Please enter New Password.';
        } else {
            if (password1.length <= 7) {
                errmsg = "8 characters";
                console.log(errmsg);
            }
            if (!password1.match(/[a-z]+/)) {
                errmsg ? errmsg = errmsg + ", 1 lowercase character" : errmsg = "1 lowercase character";
                console.log(errmsg)
            }
            if (!password1.match(/[A-Z]+/)) {
                errmsg ? errmsg = errmsg + ", 1 uppercase character" : errmsg = "1 uppercase character";
                console.log(errmsg)
            }
            if (!password1.match(/[0-9]+/)) {
                errmsg ? errmsg = errmsg + ", 1 number" : errmsg = "1 number";
                console.log(errmsg)
            }
            if (errmsg) {
                errors.password1 = `Password should contain atleast ${errmsg}`;
            }
        }
        if (!password2 || password2.length <= 0) errors.password2 = 'Please enter Confirm New Password.';
        if (password1 && password2 && password1 !== password2) errors.password2 = 'Password mismatch.';
        setError(errors)
        return isEmpty(errors)
    }

    const handleResetSubmit = () => {
        if (validatePassword()) {
            startLoading();
            validate();
            service.reset_password({ username: username, password: password1 }, res => {
                stopLoading();
                navigate('/login')
            });
        }
    }

    return (
        <>
            <div className="login-section d-flex justify-content-center">
                <div className="w-100">
                    <div className="col-md-6 col-lg-5 login-wrap p-5 mx-auto shadow">
                        <img src={logo} alt="logo" style={{ width: "220px", margin: "2rem auto", display: "block" }} />
                        <form action="#" className="login-form">
                            <div className="form-floating mb-3">
                                <input autoComplete="username" type="email" className="form-control" id="floatingInput" placeholder="name@example.com"
                                    value={username || ''} disabled={true} onChange={(e) => setUserName(e.target.value)}
                                    required
                                    autoFocus />
                                <label htmlFor="floatingInput">Email address</label>
                            </div>

                            <div className="form-floating">
                                <input autoComplete="new-password" type="password" className="form-control" id="floatingPassword" placeholder="Password"
                                    onKeyUp={() => { validatePassword() }} value={password1 || ''}
                                    onChange={(e) => setPassword1(e.target.value)}
                                    required
                                />
                                <label htmlFor="floatingPassword">New Password</label>
                            </div>
                            {errors.password1 && <p className="text-danger"><small>{errors.password1}</small></p>}
                            <div className="form-floating  mt-3">
                                <input autoComplete="new-password" type="password" className="form-control" id="floatingPassword2" placeholder="Password"
                                    value={password2 || ''}
                                    onChange={(e) => setPassword2(e.target.value)}
                                    onKeyUp={() => { validatePassword() }}
                                    required
                                />
                                <label htmlFor="floatingPassword">Verify Password</label>
                            </div>
                            {errors.password2 && <p className="text-danger"><small>{errors.password2}</small></p>}
                            <div className="form-group d-md-flex py-4">
                                <button type="button" className="btn btn-success text-white btn-min btn-lg w-50 mx-auto" onClick={() => { handleResetSubmit() }}><small>Submit</small></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
