import logo from "../assets/images/chc-logo.svg";
import React, { useState, useRef } from 'react';
import { useAuth } from "../context/auth";
import * as service from "../services";
import { useNavigate } from "react-router-dom";

function Login(props) {
    const navigate = useNavigate();
    const usernameElement = useRef(null);
    const passwordElement = useRef(null);

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [errors, setErrors] = useState({})
    const { login, logout } = useAuth();

    React.useEffect(() => {
        setUserName(usernameElement.current.value || '');
        setPassword(passwordElement.current.value || '');
    }, [usernameElement, passwordElement])

    const validate = () => {
        setErrors({});
        let isValid = true;
        if (!username || username.length <= 0) {
            setErrors({ loginFailure: 'Please enter Email Address.' });
            isValid = false;
        }

        if (username && (!password || password.length <= 0)) {
            setErrors({ loginFailure: 'Please enter Password.' });
            isValid = false;
        }

        return isValid;
    };
    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleLoginSubmit();
        }
    }

    const handleLoginSubmit = () => {
        if (validate()) {
            service.login({ username, password }, (res) => {
                // console.log(res.status);
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setErrors({ loginFailure: res.msg });
                    } else {
                        sessionStorage.setItem('token', JSON.stringify(res.data));
                        service.introspect(user_res => {
                            let user = user_res.data;
                            // console.log("user Data Service ", user)
                            if (user.password_reset === true) {
                                // console.log("navigate to reset page")
                                navigate("/reset-password", { state: username });
                            } else {
                                service.authorizations(auth_res => {
                                    if (user) user.auths = auth_res.data || [];
                                    if(user.auths && user.auths.length>0){
                                        login(user);
                                    } else{
                                        logout();
                                        setErrors({ loginFailure: 'Unauthorised Access.' });
                                    }
                                    
                                });
                            }
                        });
                    }
                }
            });
        }
    }

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <>
            <div className="login-section d-flex justify-content-center">
                <div className="w-100">
                    <div className="col-md-6 col-lg-5 login-wrap p-5 mx-auto shadow">
                        <img src={logo} alt="logo" style={{ width: "220px", margin: "2rem auto", display: "block" }} />
                        <p className="text-danger"><small>{errors.loginFailure}</small></p>
                        <form action="#" className="login-form">
                            <div className="form-floating mb-3">
                                <input ref={usernameElement} autoComplete="username" type="email" className="form-control" id="floatingInput" placeholder="name@example.com"
                                    value={username || ''}
                                    onChange={(e) => setUserName(e.target.value)}
                                    required
                                    autoFocus
                                />
                                <label htmlFor="floatingInput">Email Address</label>
                            </div>
                            <div className="input-group mb-3">
                                <div className="form-floating">
                                    <input ref={passwordElement} autoComplete="current-password" type={passwordShown ? "text" : "password"} className="form-control" id="floatingPassword" placeholder="Password"
                                        value={password || ''}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <label htmlFor="floatingPassword">Password</label>
                                </div>
                                <span className="input-group-text">
                                    <span
                                        className="material-symbols-sharp clickable"
                                        onClick={() => { togglePassword() }}
                                    >
                                        {!passwordShown ? "visibility" : "visibility_off"}
                                    </span>
                                </span></div>
                            <div className="text-primary py-2 clickable" onClick={() => navigate('/forgot')}>Forgot Password</div>
                            <div className="form-group d-md-flex py-4">
                                <button type="button" className="btn btn-success text-white btn-min btn-lg w-50 mx-auto" onClick={() => { handleLoginSubmit() }} autoFocus={true}><small>Login</small></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
