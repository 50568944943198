import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import moment from "moment";
import { startLoading, stopLoading, getStatusClass, formatCurrency, isEmpty } from "../../components/Util";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import Select from 'react-select';

function ApprovalDetail(props) {
  let { user } = useAuth();

  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [poHeader, setPoHeader] = useState({});
  const [comments, setComments] = useState("");
  const [approvalcomments, setApprovalComments] = useState("");
  const [querycomments, setQueryComments] = useState("");
  const [replycomment, setReplyComment] = useState("");
  const [showAdmin] = useState(user.user_types.is_admin);
  const [showSuperUser] = useState(user.user_types.is_sper_user);
  const [lookups, setLookups] = useState({});
  const [assignedTo, setAssignedTo] = useState({});
  const [errors, setErrors] = useState({});

  const loadData = (fn) => {
    service.get_po_data("get-approval-data", { id, assigned_to_me: false }, (res) => {
      if (res.data) {
        setPoHeader(res.data);
      }
      fn();
    });
  }
  useEffect(() => {
    startLoading();
    setLoading(true);
    service.get_query_users_lookup({ po_id: id }, res => {
      let lookups = res.USER.filter(item => item.value !== user.id)
      setLookups(lookups);
    });
    loadData(() => {
      stopLoading();
      setLoading(false);
    });

    // eslint-disable-next-line
  }, []);

  const handleCommentsChange = (value) => {
    setComments(value);
  };

  const submitComments = () => {
    let payload = { comment_type: "COMMENT", po_id: id, comment_text: comments };
    startLoading();
    setLoading(true);
    service.get_po_data("submit-comments", payload, (res) => {
      if (res.data) {
        loadData(() => {
          setComments("");
          stopLoading();
          setLoading(false);
        });
      } else {
        stopLoading();
        setLoading(false);
      }
    });
  }
  const handleApprovalsChange = (value) => {
    setApprovalComments(value);
  };

  const ApproveClick = () => {
    let payload = { comment_type: "APPROVAL", po_id: id, action: 'APPROVED', action_comments: approvalcomments };
    startLoading();
    setLoading(true);
    service.get_po_data("submit-approval", payload, (res) => {
      if (res.data) {
        loadData(() => {
          setApprovalComments("");
          stopLoading();
          setLoading(false);
        });
      } else {
        console.log(
          "Record not found"
        );
        stopLoading();
        setLoading(false);
      }

    });
  }

  const RejectApprovalClick = () => {
    let payload = { comment_type: "APPROVAL", po_id: id, action: 'REJECTED', action_comments: approvalcomments };
    startLoading();
    setLoading(true);
    service.get_po_data("submit-approval", payload, (res) => {
      if (res.data) {
        loadData(() => {
          setApprovalComments("");
          stopLoading();
          setLoading(false);
        });
      } else {
        console.log(
          "Record not found"
        );
        stopLoading();
        setLoading(false);
      }

    });
  }

  const validateData = () => {
    let errors = {}
    if (!assignedTo.value || assignedTo.value.length <= 0) errors.queried_to_id = 'Please assign to User';
    setErrors(errors)
    return isEmpty(errors)
  }

  const handleQueryChange = (value) => {
    setQueryComments(value);
  };

  const submitQuery = () => {
    if (validateData()) {
      let payload = {
        comment_type: "QUERY",
        po_id: id,
        comment_text: querycomments,
        queried_to_id: assignedTo.value
      };
      startLoading();
      setLoading(true);
      service.get_po_data("submit-comments", payload, (res) => {
        if (res.data) {
          loadData(() => {
            setQueryComments("");
            setAssignedTo("");
            stopLoading();
            setLoading(false);
          });
        } else {
          // console.log(
          //   "Record not found"
          // );
          stopLoading();
          setLoading(false);
        }

      });
    }
  }

  const submitReply = (item) => {
    let payload = {
      comment_type: "REPLY",
      po_id: id,
      comment_text: replycomment,
      queried_to_id: user.id,
      parent_id: item.id
    };
    startLoading();
    setLoading(true);
    service.get_po_data("submit-comments", payload, (res) => {
      if (res.data) {
        loadData(() => {
          setReplyComment("");
          stopLoading();
          setLoading(false);
        });
      } else {
        // console.log(
        //   "Record not found"
        // );
        stopLoading();
        setLoading(false);
      }

    });
  }

  return (
    <>
      {!loading && poHeader ? <div className="container py-3">
        <div className="row">
          <div className="col">
            <h3 style={{ display: "inline", verticalAlign: "middle", paddingRight: "20px" }}>Purchase Order #{poHeader.padded_po_number}</h3>
            <span className={getStatusClass(poHeader.approval_status_name) + " py-2 px-2"}>
              {poHeader.approval_status_name}
            </span>
          </div>
          <div className="col flex-grow-0">
            {poHeader.approval_status_id === "APPROVED" && <button className="btn btn-min btn-success text-white text-nowrap" onClick={() => { service.download_po(poHeader.id, poHeader.padded_po_number, () => { console.log("done") }); }}>Download PO</button>}
          </div>
        </div>

        <div className="row">
          <div className="col-6 pt-3">
            <small className="text-muted">PURCHASE ORDER NUMBER</small>
            <br />
            {poHeader.padded_po_number}
          </div>
          <div className="col-3 pt-3">
            <small className="text-muted">SERVICE GROUP</small>
            <br />
            {poHeader.service_group}
          </div>
          <div className="col-3 pt-3">
            <small className="text-muted">WORK TYPE</small>
            <br />
            {poHeader.work_type}
          </div>
        </div>
        <div className="row">
          <div className="col-6 pt-3">
            <small className="text-muted">CONTRACTOR NAME</small>
            <br />
            {poHeader.supplier_name}
          </div>
          <div className="col-6 pt-3">
            <small className="text-muted">CARE HOME NAME</small>
            <br />
            {poHeader.facility_name}
          </div>
        </div>
        <div className="row">
          <div className="col-6 pt-3">
            <small className="text-muted">CONTRACTOR ADDRESS</small>
            <br />
            {poHeader.supplier_address && poHeader.supplier_address.split(", ").map((line, i) => <div key={i}>{line}</div>)}
          </div>

          <div className="col-6 pt-3">
            <small className="text-muted">CARE HOME ADDRESS</small>
            <br />
            {poHeader.facility_address && poHeader.facility_address.split(", ").map((line, i) => <div key={i}>{line}</div>)}
          </div>
        </div>
        {/* <div className="row">
          <div className="col-6 pt-3">
            <small className="text-muted">JOB SHEET</small>
            <br />
            <span className="text-darkprime" style={{ textDecoration: "underline", cursor: "pointer" }}>EventWorksheet132.pdf</span>
          </div>
        </div> */}
        <div className="row py-4">
          <div className="col-12">

            <div className="table-responsive">
              <table className="table table-bordered po-table m-0">
                <thead>
                  <tr>
                    <th scope="col" width="13%"><h6 className="text-darkprime"><b>Job Number</b></h6></th>
                    <th scope="col"><h6 className="text-darkprime"><b>Description</b></h6></th>
                    <th scope="col" width="10%"><h6 className="text-darkprime"><b>Quantity</b></h6></th>
                    <th scope="col" width="10%"><h6 className="text-darkprime"><b>Unit Price</b></h6></th>
                    <th scope="col" width="10%"><h6 className="text-darkprime"><b>Extended Price</b></h6></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-t0">{poHeader.job_sheet_number}</td>
                    <td className="border-t0"><p className="mb-0">{poHeader.instructions}</p></td>
                    <td className="border-t0">{poHeader.quantity}</td>
                    <td className="border-t0">&#163; {formatCurrency(poHeader.unit_price, 2)}</td>
                    <td className="text-end border-t0">&#163; {formatCurrency(poHeader.price, 2)}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" rowSpan="4" style={{ borderWidth: '0' }}>
                      <h6 className="text-darkprime"><strong>Finance Code</strong></h6>
                      {poHeader.finance_code || '-'}
                    </td>
                    <td rowSpan="4" style={{ borderWidth: '0' }}>
                      {poHeader.vat_number && <div>
                        <h6 className="text-darkprime"><strong>VAT NUMBER</strong></h6>
                        {poHeader.vat_number}
                      </div>}
                    </td>

                    <td><h6 className="text-darkprime"><strong>Sub Total</strong></h6> </td>
                    <td className="text-end">&#163; {formatCurrency(poHeader.price, 2)}</td>
                  </tr>
                  <tr>
                    <td><h6 className="text-darkprime"><strong>VAT</strong></h6></td>
                    <td className="text-end">&#163; {formatCurrency(poHeader.vat, 2)}</td>
                  </tr>
                  <tr className="border-0">
                    <td className="border-b0"><h6 className="text-darkprime"><strong>Grand Total</strong></h6></td>
                    <td className="border-b0 text-end">&#163; {formatCurrency(poHeader.net_price, 2)}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
        <div className="row py-4 comments">
          <div className="col-12">
            <h3 className="section-title">Add Comment</h3>
          </div>
          <div className="col-12 mt-2">
            <textarea className="form-control comment-box" rows="2" placeholder="Leave a comment" onChange={(e) => handleCommentsChange(e.target.value)} value={comments}  ></textarea>
          </div>
          <div className="col-12 d-flex justify-content-end mt-3">
            <button type="button" className="btn btn-min btn-success text-white " onClick={submitComments} disabled={comments.trim().length === 0}>Post Comment</button>
          </div>

          {poHeader.comments && poHeader.comments.filter(item => item.comment_type === "COMMENT").length > 0 && <div className="col-12 mt-3">
            <div className="col-12">
              <h5>Comments
                <span className="badge bg-secondary ms-1 opacity-75 py-1 px-2"><small>{poHeader.comments.filter(item => item.comment_type === "COMMENT").length}</small></span></h5>
            </div>
            {poHeader.comments.filter(item => item.comment_type === "COMMENT").map(item => {
              const commented_at = moment(new Date(item.commented_at_ms));
              let commented_at_dt = commented_at.format('DD-MMM-YYYY hh:mm A');

              return <div className="d-flex flex-row p-3 st-comments" key={item.id}>
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-row align-items-center">
                      <span className="mr-2"><b>{item.commented_by_name}</b></span>
                    </div>
                    <small className="comms-date">{commented_at_dt}</small>
                  </div>
                  <p className="text-justify comment-text mb-0">{item.comment_text}</p>
                </div>
              </div>
            })}
          </div>}
        </div>
        {poHeader.approval_steps && poHeader.approval_steps.length > 0 && <div className="row py-4 progress">
          <div className="col-12">
            <h3 className="section-title">Approvals</h3>
          </div>
          <div className="col-12 pt-2">
            <ul className="progress-indicator">
              {poHeader.approval_steps.map(item => {
                let color = "pending";
                let icon = ""
                if (item.status_id === "PENDING") {
                  color = "pending"
                  icon = "pending"
                }
                if (item.status_id === "APPROVED") {
                  color = "completed";
                  icon = "thumb_up";
                }
                if (item.status_id === "REJECTED") {
                  color = "danger";
                  icon = "thumb_down";
                }
                if (item.status_id === "ESCALATED") {
                  color = "danger";
                  icon = "arrow_circle_right";
                }
                if (item.is_active_step) {
                  color = "active";
                  icon = "pending_actions";
                }
                return <li key={item.id} className={color}>
                  <i className="material-symbols-sharp" >{icon}</i>
                  <span className="bubble"></span>
                  <span className="text-body">{item.approver_name}</span>
                </li>
              })}
            </ul>
          </div>
          {poHeader.lifecycle_status_name === 'Pending Approval' && (showAdmin || showSuperUser || poHeader.approval_assigned_to_id === user.id) && <>
            <div className="col-12 mt-3">
              <textarea className="form-control comment-box" rows="3" placeholder="Leave a message" onChange={(e) => handleApprovalsChange(e.target.value)} value={approvalcomments}  ></textarea>
            </div>
            <div className="col-12 d-flex justify-content-end mt-3">
              <button type="button" className="btn btn-min btn-success text-white text-nowrap me-2" onClick={e => { ApproveClick() }} disabled={approvalcomments.trim().length === 0}>Approve</button>
              <button type="button" className="btn btn-min btn-danger text-white text-nowrap" onClick={e => { RejectApprovalClick() }} disabled={approvalcomments.trim().length === 0}>Reject</button>
            </div>
          </>}
          {poHeader.comments && poHeader.comments.filter(item => item.comment_type === "APPROVAL").length > 0 && <div className="col-12 mt-3">
            <div className="col-12">
              <h5>Approval/Rejection Comments
                <span className="badge bg-secondary ms-1 opacity-75 py-1 px-2"><small>{poHeader.comments.filter(item => item.comment_type === "APPROVAL").length}</small></span>
              </h5>
            </div>
            {poHeader.comments.filter(item => item.comment_type === "APPROVAL").map(item => {
              const commented_at = moment(new Date(item.commented_at_ms));
              let commented_at_dt = commented_at.format('DD-MMM-YYYY hh:mm A');
              return <div className="d-flex flex-row p-3 st-comments" key={item.id}>
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-row align-items-center">
                      <span className="mr-2"><b>{item.commented_by_name}</b></span>
                    </div>
                    <small className="comms-date">{commented_at_dt}</small>
                  </div>
                  <p className="text-justify comment-text mb-0">{item.comment_text}</p>
                </div>
              </div>
            })}
          </div>}
        </div>}

        {lookups && lookups.length > 0 && <>
          <div className="row  m-0">
            <div className="col-12 section-title p-0">
              <div className="row mb-1 mt-5">
                <div className="col-6 col-lg-9"><h3 >Query</h3></div>
                <div className="col-6 col-lg-3 mb-3">
                  <Select placeholder="Assigned To"
                    value={assignedTo.queried_to_id}
                    options={lookups || []}
                    className="st-select"
                    menuPlacement="auto"
                    required
                    onChange={(e) => { setAssignedTo(e) }}
                  />
                  {errors.queried_to_id && <div className="text-danger"><small>{errors.queried_to_id}</small></div>}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12" style={{ paddingTop: "15px" }}>
            <textarea className="form-control comment-box" rows="3" placeholder="Leave a query" onChange={(e) => handleQueryChange(e.target.value)} value={querycomments}></textarea>
          </div>
          <div className="col-12 d-flex justify-content-end mt-3">
            <button type="button" className="btn btn-min btn-warning text-white text-nowrap" onClick={submitQuery} disabled={querycomments.trim().length <= 0 || !assignedTo.value || assignedTo.value.length <= 0}>Submit Query</button>
          </div>
        </>}
        {poHeader.comments.filter(item => item.comment_type === "QUERY").length > 0 && <div className="col-12 mt-3">
          <div className="col-12">
            <h5>Queries
              <span className="badge bg-secondary ms-1 opacity-75 py-1 px-2"><small>{poHeader.comments.filter(item => item.comment_type === "QUERY").length}</small></span>
            </h5>
          </div>
          {poHeader.comments.filter(item => item.comment_type === "QUERY").map((item, index) => {
            const commented_at = moment(new Date(item.commented_at_ms));
            let commented_at_dt = commented_at.format('DD-MMM-YYYY hh:mm A');
            let replies = poHeader.comments ? poHeader.comments.filter(item => item.comment_type === "REPLY") : [];
            item.reply = replies.filter(r => r.parent_id === item.id);
            return <div className="d-flex flex-row p-3 st-comments" key={index}>
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-row align-items-center">
                    <span className="mr-2"><b>{item.commented_by_name}</b></span>
                  </div>
                  <small className="comms-date">{commented_at_dt}</small>
                </div>
                <p className="text-justify comment-text mb-0">{item.comment_text}</p>
                {item.queried_to_id === user.id && ((item.reply && item.reply.length < 1)) && <>
                  <div className="d-flex flex-row user-feed mt-1">
                    <span className="d-flex align-items-center clickable" onClick={() => {
                      let _comments = poHeader.comments;
                      _comments = _comments.map(_c => {
                        _c.showReply = _c.id === item.id
                        return _c;
                      })
                      poHeader.comments = _comments;
                      setPoHeader({ ...poHeader });
                      setReplyComment("")
                    }}><span className="material-symbols-sharp me-1">reply</span>Reply</span>
                  </div>
                </>}
                {item.showReply && <div className="w-100">
                  <div className="col-12" style={{ paddingTop: "15px" }}>
                    <textarea className="form-control reply-box" rows="2" placeholder="Leave a query" onChange={(e) => setReplyComment(e.target.value)} value={replycomment}></textarea>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-3">
                    <button type="button" className="btn btn-min btn-warning text-white text-nowrap"
                      onClick={() => { submitReply(item) }} disabled={replycomment.trim().length === 0}>Submit Reply</button>
                  </div>
                </div>}

                {item.reply && item.reply.length > 0 && item.reply.map((r, rIndex) => {
                  const commented_at = moment(new Date(r.commented_at_ms));
                  let commented_at_dt = commented_at.format('DD-MMM-YYYY hh:mm A');
                  return <div className="d-flex flex-row p-3 pt-2 pb-0 mt-3 st-comments" key={rIndex} >
                    <div className="w-100">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-row align-items-center">
                          <span className="mr-2"><b>{r.commented_by_name}</b></span>
                        </div>
                        <small className="comms-date">{commented_at_dt}</small>
                      </div>
                      <p className="text-justify comment-text mb-0">{r.comment_text}</p>
                    </div>
                  </div>
                })}
              </div>
            </div>
          })}
        </div>}

        <div className="col-12 d-flex justify-content-end mt-3">
          <button type="button" className="btn btn-min btn-secondary text-white " onClick={e => { navigate(-1) }}>Go Back</button>
        </div>
      </div> : <div className="blank-div">Record not found</div>
      }
    </>
  )
}

export default ApprovalDetail;
