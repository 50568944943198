import { post } from "./service";

export const get_care_homes = (payload, onDone) => {
    post("/api/carehomes/grid", payload, (res) => {
        onDone(res);
    });
};

export const upsert_care_home = (payload, onDone) => {
    post("/api/carehomes/upsert", payload, (res) => {
        onDone(res);
    });
};

export const duplicate_check_care_home = (payload, onDone) => {
    post("/api/carehomes/duplicate_check", payload, (res) => {
        onDone(res);
    });
};
