import React from "react";

function Footer() {
  return (
    <>
      <footer className="text-center text-lg-start bg-primary text-white d-flex align-items-center justify-content-center">
        <div className="text-center">
          © 2023 <b>Choice Care</b>. All rights reserved
        </div>
      </footer>
    </>
  );
}

export default Footer;
